import {onMounted, onUnmounted, ref,} from "vue"
import {getActivePinia,} from "pinia"
import {useAppUiStore,} from "@/assets/js/src/pinia/appUi.js"

export function useIsTop () {
    let activePinia = getActivePinia()
    let appUiStore = useAppUiStore(activePinia)
    let isTop = ref(true)
    let onScroll = (event) => {
        isTop.value = (window.pageYOffset || event.target.scrollTop || 0) <= 100
    }

    onMounted(()=> {
        window.addEventListener('scroll', onScroll)
        appUiStore.addTopClass('bs-app--top')
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', onScroll)
        appUiStore.removeTopClass('bs-app--top')
    })
}
