import '@/assets/js/src/util/declareglobals'
import '@/assets/js/src//util/logger.client'
import {notifyError, ServiceWorkerRegError,} from '@/assets/js/src/util/bugsnag'

if ('serviceWorker' in navigator && globalThis.hasLocalStorageAccess) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
            .catch(registrationError => {
                notifyError(new ServiceWorkerRegError(registrationError?.message))
            })
    })
}

// Polyfill for IE
if (!globalThis.fetch) {
    require('whatwg-fetch')
}

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector
}

if (!globalThis.IntersectionObserver) {
    require('intersection-observer')
}
