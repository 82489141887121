let globalThis = (1, eval)('this')
if (globalThis.runningMain) throw new Error('double execution prevented')
globalThis.runningMain = true

globalThis.isServer = import.meta.env.SSR

// Browser environment sniffing
globalThis.inBrowser = typeof window !== 'undefined'
globalThis.inWeex = typeof WXEnvironment !== 'undefined' && !!WXEnvironment.platform
globalThis.weexPlatform = globalThis.inWeex && WXEnvironment.platform.toLowerCase()
globalThis.UA = globalThis.inBrowser && window.navigator.userAgent.toLowerCase()
globalThis.isIE = globalThis.UA && /msie|trident/.test(globalThis.UA)
globalThis.isIE9 = globalThis.UA && globalThis.UA.indexOf('msie 9.0') > 0
globalThis.isEdge = globalThis.UA && globalThis.UA.indexOf('edge/') > 0
globalThis.isAndroid = (globalThis.UA && globalThis.UA.indexOf('android') > 0) || (globalThis.weexPlatform === 'android')
globalThis.isIOS = (globalThis.UA && /iphone|ipad|ipod|ios/.test(globalThis.UA)) || (globalThis.weexPlatform === 'ios')
globalThis.isChrome = globalThis.UA && /chrome\/\d+/.test(globalThis.UA) && !globalThis.isEdge
globalThis.isPhantomJS = globalThis.UA && /phantomjs/.test(globalThis.UA)
globalThis.isFF = globalThis.UA && globalThis.UA.match(/firefox\/(\d+)/)
globalThis.isSafari = globalThis.UA && globalThis.UA.indexOf('safari') !== -1 && globalThis.UA.indexOf('chrome') === -1
globalThis.isTouchDevice = globalThis.inBrowser && (function () {
    let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
    let mq = function (query) {
        return window.matchMedia(query).matches
    }
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true
    }

    let query = [ '(', prefixes.join('touch-enabled),('), 'heartz', ')', ].join('')

    return mq(query)
}())
globalThis.isMac = globalThis.inBrowser && navigator.platform.toLowerCase().indexOf('mac') !== -1

globalThis.clone = obj => {
    return JSON.parse(JSON.stringify(obj))
}

Array.prototype.swap = function (x, y) {
    var b = this[x]
    this[x] = this[y]
    this[y] = b

    return this
}

function at (n) {
    // ToInteger() abstract op
    n = Math.trunc(n) || 0
    // Allow negative indexing from the end
    if (n < 0) n += this.length
    // OOB access is guaranteed to return undefined
    if (n < 0 || n >= this.length) return undefined

    // Otherwise, this is just normal property access
    return this[n]
}

const TypedArray = Reflect.getPrototypeOf(Int8Array)
for (const C of [ Array, String, TypedArray, ]) {
    Object.defineProperty(C.prototype, "at",
        { value: at,
            writable: true,
            enumerable: false,
            configurable: true, })
}
