import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {Perf,} from "@/assets/js/src/util/performance"

export function createI18nInstance ({pinia,}) {

    Perf.start('createI18n')

    let langStore = useLangStore(pinia)

    const i18n = {
        ...useTranslation(langStore),
    }

    if (!import.meta.env.SSR && typeof cookieManager.get('bs-locale') === 'undefined') {
        cookieManager.set('bs-session-locale', langStore.locale, {
            secure: true,
        })
    }

    Perf.end('createI18n')

    return i18n
}
