import {notifyError,} from '@/assets/js/src/util/bugsnag'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {loadDefaultContainer, initConsent,} from '@/assets/js/src/util/matomoTagManager'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {Logger,} from '@/assets/js/src/util/logger'
import {useSearchStore} from "@/assets/js/src/modules/search/_pinia/search.js"

const COOKIE = 'bs-matomo-opt-out'

const EVENT_BLACKLIST = [ 'autocomplete','text-selection', ]

export const initBSA = function ({app, router, pinia,}) {

    let isProd = !import.meta.env.DEV

    if(isProd) {
        // Matomo
        let cookieConsentValue = 'Declined (not set)'
        let consentCookie = cookieManager.get('CookieConsent')
        if(consentCookie && consentCookie !== '-1') {
            try {
                consentCookie = consentCookie.replace(/%2c/g, ",").replace(/'/g, '"').replace(/([{\[,])\s*([a-zA-Z0-9_]+?):/g, '$1"$2":')
                consentCookie = JSON.parse(consentCookie)
                consentCookie.statistics && (cookieConsentValue = 'Consented')
            } catch { /* empty */ }
        }

        let userStore = useUserStore(pinia)

        const _paq = window._paq = window._paq || []
        _paq.push([ 'requireCookieConsent', ])
        _paq.push([ 'enableLinkTracking', ])
        _paq.push([ 'setCustomDimension', 1, userStore.loggedIn ? 'Member' : 'Visitor', 'visit', ])
         
        _paq.push([ 'setCustomDimension', 2, __BS_TYPE__, 'visit', ])
        _paq.push([ 'setCustomDimension', 3, cookieConsentValue, ])

        if(cookieConsentValue === 'Consented') {
            _paq.push([ 'setCookieConsentGiven', ])
            cookieManager.get(COOKIE) && cookieManager.remove(COOKIE)
        }

        const trackMatomoPageView = function (to, from) {
            if (cookieManager.get(COOKIE)) {
                return
            }

            const Matomo = window.Matomo.getAsyncTracker()

            let url = router.resolve(to.fullPath).href
            let referrerUrl = from && from.fullPath
                ? router.resolve(from.fullPath).href
                : undefined

            referrerUrl && Matomo.setReferrerUrl(decodeURIComponent(referrerUrl))

            Matomo.setCustomUrl(decodeURIComponent(url))
            Matomo.trackPageView(url)

        };

        const trackMatomoEvent = function (to) {
            if (cookieManager.get(COOKIE)) {
                return
            }

            if(to.meta?.uiType === 'SearchView') {
                useSearchStore(pinia).sendBsaEvent(to.params?.slug)
            }
        };

        (function () {
            _paq.push([ 'setTrackerUrl', 'https://stats.erf.de/matomo.php', ])
            _paq.push([ 'setSiteId', pinia.state.value.baseUrl === 'https://localhost:52443' || pinia.state.value.baseUrl === 'https://dev.bibleserver.com' ? 2 : 1, ])

            return new Promise((resolve, reject) => {
                const script = document.createElement('script')
                script.type = 'text/javascript'
                script.async = true
                script.src = 'https://stats.erf.de/matomo.js'

                const head = document.head || document.getElementsByTagName('head')[0]
                head.appendChild(script)

                script.onload = resolve
                script.onerror = reject
            })
        })().then(() => {
            return new Promise((resolve) => {
                const checkInterval = 50
                const timeout = 3000
                const waitStart = Date.now()

                const interval = setInterval(() => {
                    if (window.Piwik) {
                        clearInterval(interval)

                        return resolve()
                    }

                    if (Date.now() >= waitStart + timeout) {
                        clearInterval(interval)

                        throw new Error(`[matomo]: window.Piwik undefined after waiting for ${timeout}ms`)
                    }
                }, checkInterval)
            })
        })
            .then(()=>{

                const Matomo = window.Matomo.getAsyncTracker()

                if (app) {
                    app.config.globalProperties.$matomo = Matomo
                }

                trackMatomoPageView(router.currentRoute.value)
                if(router.currentRoute.value?.meta?.hasInitEvent) {
                    trackMatomoEvent(router.currentRoute.value)
                }

                router.afterEach((to, from) => {
                    trackMatomoPageView(to, from)

                    Matomo.enableLinkTracking()
                })
            }).catch((error) => {
                if (error.target) {
                    console.warn(`[matomo] An error occurred trying to load ${error.target.src}. ` +
                    'If the file exists you may have an ad- or trackingblocker enabled.')
                } else {
                    notifyError(error)
                }
            })
    }

    app.config.globalProperties.$bsa = {
        page: (obj) => {
            if (isProd) {
                //Matomo
                if (!cookieManager.get(COOKIE) && window._paq) {
                    window._paq.push([ 'setCustomUrl', decodeURIComponent(obj.page), ])
                    window._paq.push([ 'trackPageView', obj.title, ])
                }
            } else {
                Logger.debug({
                    msg: 'ga page called',
                    obj,
                })
            }
        },
        event: (obj) => {
            if(EVENT_BLACKLIST.includes(obj?.eventCategory)) {
                return
            }

            if (isProd) {
                //Matomo
                !cookieManager.get(COOKIE) && window._paq && window._paq.push([ 'trackEvent',...Object.values(obj), ])
            } else {
                Logger.debug({
                    msg: 'ga event called',
                    obj,
                })
            }
        },
        link: (obj) => {
            if (isProd) {
                //Matomo
                !cookieManager.get(COOKIE) && window._paq && window._paq.push([ 'trackLink', obj.eventLabel, obj.eventAction, ])
            } else {
                Logger.debug({
                    msg: 'ga link called',
                    obj,
                })
            }
        },
    }

    if (isProd) {
        window.CookiebotCallback_OnDialogDisplay = function () {
            let appEl = document.querySelector('.bs-app')
            let dialogEl = document.querySelector('.cc-dialog-container.visible')
            if(appEl && dialogEl) {
                appEl.style.setProperty('--cch', `${(dialogEl.offsetHeight || 0)}px`)
            }

            window.addEventListener('CookiebotOnAccept', function () {
                window._paq && window._paq.push([ 'setCustomDimension', 3, 'Consented', ])
                let $bsa = app?.config?.globalProperties?.$bsa
                $bsa && $bsa.event({
                    category: 'Cookiebot',
                    action: 'Consented',
                })

                document.querySelector('.bs-app')?.style?.setProperty('--cch', '0px')
            })

            window.addEventListener('CookiebotOnDecline', function () {
                window._paq && window._paq.push([ 'setCustomDimension', 3, 'Declined', ])
                let $bsa = app?.config?.globalProperties?.$bsa
                $bsa && $bsa.event({
                    category: 'Cookiebot',
                    action: 'Declined',
                })

                document.querySelector('.bs-app')?.style?.setProperty('--cch', '0px')
            })
        }

        window.addEventListener("load", function () {
            loadDefaultContainer()
            initConsent(pinia)
        })

        window.addEventListener('CookiebotOnConsentReady', ()=> initConsent(pinia))
    }
}
