<script setup>
import {getActivePinia, storeToRefs,} from 'pinia'
import {useSnackbarStore,} from '@/assets/js/src/modules/snackbar/_pinia/snackbar'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import SnackbarItem from "@/assets/js/src/modules/snackbar/_components/SnackbarItem.vue"
import {computed, watch,} from "vue"
import {useRoute,} from "vue-router"

const route = useRoute()
const activePinia = getActivePinia()
const appUiStore = useAppUiStore(activePinia)
const {menu,} = storeToRefs(appUiStore)
const autocompleteStore = useAutocompleteStore(activePinia)
const {isComponentVisible,} = storeToRefs(autocompleteStore)
const snackbarStore = useSnackbarStore(activePinia)
const {snackbars,} = storeToRefs(snackbarStore)

const zIndex = computed(()=>{
    return (isComponentVisible.value || menu.value) ? 9990 : 10200
})

watch(route,(newRoute)=>{
    snackbars.value.forEach((snackbar) => {
        if (snackbar.routes.length) {
            if (!snackbar.routes.includes(newRoute.meta.uiType)) {
                closeSnackbar()
            }
        }
    })
})

const closeSnackbar = (uniqueId) => {
    let snackbar = snackbars.value.find((snackbar) => snackbar?.uniqueId === uniqueId)
    if (snackbar.closeCb) {
        snackbar.closeCb()
    }
    snackbarStore.removeSnackbarByUniqueId({uniqueId,})
}
</script>

<template>
    <div
        id="snackbar__wrapper"
        class="hidden-print-only"
        aria-live="polite"
        :style="`z-index: ${zIndex}`"
    >
        <snackbar-item
            v-for="snackbar in snackbars"
            :key="snackbar.uniqueId"
            :snackbar="snackbar"
            @close="closeSnackbar"
        />
    </div>
</template>

<style lang="scss">
.bs-app #snackbar__wrapper {
    --position-margin: #{map-deep-get($bs-xl, text,textNav,padding)}px;
    
    position: fixed;
    inset: 0 var(--position-margin) calc(var(--position-margin) + var(--cch)) var(--position-margin);
    z-index: 10100;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(100vw - 2 * var(--position-margin));
    pointer-events: none;

    @media screen and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
        --position-margin: 0px;
    }
}
</style>
