import { useLangStore, } from '@/assets/js/src/modules/lang/_pinia/lang'
import { checkResponse, handleException, } from '@/assets/js/src/util/apiTools'
import fetch from '@/assets/js/src/util/fetch'
import { getDefaultHeaders, } from '@/assets/js/src/util/fetch/defaultHeaders'
import { defineStore, getActivePinia, } from 'pinia'
import mdVars from '@/assets/js/src/style/json/variables-md.json'

export const useAppUiStore = defineStore('appUi', {
    state: () => ({
        sidebarState: {
            reload: false,
            provider: '',
            mode: '',
        },

        bookMenuUsed: false,

        //
        pageNotFound: false,
        blockHeadroom: false,
        printBtnDisabled: true,
        printing: false,

        // Audio Player
        audioPlayer: false,

        // Notifications
        banner: false,
        notifications: {},
        menuNotificationVisible: false,
        report: false,

        // Home
        home: false,
        homeHide: false,
        homeTop: false,
        homeTransition: false,
        backgroundFade: true,
        homeLogoHidden: false,
        homeLoad: false,

        // Pages and Overlays
        overlay: false,
        overlayModal: false,
        overlayModalSource: {
            component: '',
            data: {},
        },
        countRouteChange: 0,
        lastRoutes: [],
        savedYPosition: 0,
        savedYPositionOverlay: 0,
        claim: false,
        ampLink: '',

        // Routing
        userContentRoute: null,
        backOverlayRoute: '',
        lastRoute: null,

        // Header
        mainHeaderClasses: {},

        // Menu
        menu: false,

        // Sidebar - Extrabox
        sidebarOpen: false,
        sidebarOnMove: false,
        reloadExtraBox: false,
        extraBox: {
            show: false,
            component: '',
            data: {},
            key: '',
        },
        topClass: [],

        // Help Tour
        helpTour: '',
        startHelpTour: false,
        helpTourRunning: false,

        // Mobile Helper
        mobileView: false,
        mobileViewLandscape: false,
        criticalMessagesLoaded: false,

        sidebarOpenWidth: parseInt(mdVars.sidebar.open.width),
    }),
    getters: {
        existsLastRoute: (state) => state.lastRoute !== null && state.lastRoute.length !== 0 && state.countRouteChange >= 2,
        sidebarOpenWidthPx: (state) => {
            return typeof state.sidebarOpenWidth === 'string' ? state.sidebarOpenWidth : `${state.sidebarOpenWidth}px`
        },
    },
    actions: {
        async setSidebarState (payload) {
            this.sidebarState = globalThis.clone(payload)
        },
        setBookMenuUsed (payload) {
            this.bookMenuUsed = payload
        },
        increaseCounter (payload) {
            if (payload) {
                this.lastRoutes.push(payload)
                this.countRouteChange++
            }
        },
        resetSidebarState () {
            this.sidebarState = {
                reload: false,
                provider: '',
                mode: '',
            }
        },
        setOverlay (payload) {
            // Beim Schließen und einer gespeicherten y-Scrollposition dahin scrollen
            if (!import.meta.env.SSR) {
                if (!payload && this.savedYPosition !== 0) {
                    let pos = this.savedYPosition
                    this.savedYPosition = 0
                    setTimeout(() => {
                        window.scrollTo(0, pos)
                    }, 150)
                }
            }
            this.overlay = payload
        },
        setOverlayModal ({ show, next, force, }) {
            // Merke die y-Scrollposition (z.B. der TextView) bei nicht routen modals, da "display:none" auf main automatisch nach oben scrollt
            return new Promise((resolve, reject) => {
                if (!import.meta.env.SSR) {
                    if (show) {
                        this.savedYPosition = window.pageYOffset
                    } else if (this.overlayModal && !show && this.claim && !force) {
                        document.getElementById('claimDialog')?.__vue__?.beforeRouteLeave(null, null, (ok) => {
                            if (typeof ok === 'undefined') {
                                this.overlayModal = show
                                resolve()
                                next && next()
                            }
                        })
                        reject()

                        return
                    }
                }
                this.overlayModal = show
                resolve()
            })
        },
        async showOverlayModal ({ component, data, topClass, }) {
            topClass = topClass || ''
            this.overlayModalSource = { component, data, topClass, }
            await this.setOverlayModal({ show: true, })
        },
        async loadNotifications () {
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: '/api/notifications',
                    options: {
                        headers: getDefaultHeaders({}),
                    },
                    loading: false,
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.notifications = apiResponse.data
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, false, false)
            }
        },
        showExtraBox ({ component, data, key, }) {
            if(this.extraBox.key === key) {
                this.extraBox.data = data
                this.reloadExtraBox = true

                return
            }

            this.extraBox = {
                show: true,
                component,
                data,
                key,
            }
        },
        hideExtraBox () {
            this.extraBox = {
                show: false,
                component: '',
                data: {},
                key: '',
            }
        },
        async showAudioPlayer (visibility) {

            if (visibility) {
                let langStore = useLangStore(getActivePinia())
                !langStore.areAsyncKeysLoaded([ 'audioPlayer', ]) &&
                await langStore.fetchAsyncMessages({ keys: [ 'audioPlayer', ], })
            }

            this.audioPlayer = visibility
        },
        removeTopClass (payload) {
            this.topClass = this.topClass.filter((topClass) => topClass !== payload)
        },
        addTopClass (payload) {
            if(!this.topClass.includes(payload)) {
                this.topClass.push(payload)
                this.topClass = globalThis.clone(this.topClass)
            }
        },
    },
})
