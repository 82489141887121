import { defineStore, getActivePinia, } from 'pinia'
import fetch from '@/assets/js/src/util/fetch'
import { getDefaultHeaders, } from '@/assets/js/src/util/fetch/defaultHeaders'
import { checkResponse, handleException, } from '@/assets/js/src/util/apiTools'
import { useAppUiStore, } from '@/assets/js/src/pinia/appUi'
import { useChatListStore, } from '@/assets/js/src/modules/chat/_pinia/chatList'
import { mergeDeep, } from 'eslint-plugin-vuetify/lib/util/helpers'
import { useUserStore, } from '@/assets/js/src/modules/user/_pinia/user'
import { useContentReportStore, } from '@/assets/js/src/modules/report/_pinia/contentreport'

export const API_REST_CHAT = '/api/chat'
export const EXT_API_CHAT_MESSAGE = 'message'
export const API_GET_DAILY_LIMIT = '/api/chat_daily_limit'
export const MESSAGES_LIMIT_PER_CHAT = 10

export const useChatItemStore = defineStore('chatItem', {
    state: () => ({
        chat: {},
        active: false,
        activeUuid: null,
        reload: false,
        dailyLimit: 0,
        canonicals: [],
        invalidInput: false,
        sendingMessage: false,
    }),
    getters: {
        messagesFormatted: (state) => {
            if(typeof state.chat['messages'] === 'undefined'){
                return []
            }

            let userIndex = 0

            return state.chat.messages.map((message) => {
                if(message.role === 'user'){
                    userIndex++
                }

                return {
                    ...message,
                    userIndex,
                }
            })
        },
        chatResources: (state) => (prefix) => {
            let metadataList = state.chat.context?.metadata?.metadata_list ?? []

            if(!metadataList.length){
                return ''
            }

            let metadataListTransformed = metadataList
                .reduce((acc, item) => {
                    if(!acc.some(n=>n.link === item.link) && 'undefined' !== typeof item.link) {
                        acc.push({
                            link: item.link,
                            title: item.title.split(' – ')[0].trim(),
                            resource: item.resource,
                        })
                    }

                    return acc
                },[])
                .map(item => `<a href="${item.link}" title="${item.title}">${item.resource}</a>`)

            return metadataListTransformed.length
                ? `${prefix}: ${metadataListTransformed.join(', ')}`
                : ''
        },
        messagesLimitReached: (state) => {
            if(typeof state.chat['messages'] === 'undefined'){
                return false
            }

            return state.chat.messages.filter((message) => message.role === 'user').length >= MESSAGES_LIMIT_PER_CHAT
            && state.chat.messages[state.chat.messages.length - 1].role === 'assistant'
        },
        hasErrorMessage: (state) => {
            if(typeof state.chat['messages'] === 'undefined'){
                return false
            }

            return state.chat.messages.some((message) => message.role === 'assistant' && message.error)
        },
    },
    actions: {
        async getChat ({ uuid, }) {
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_REST_CHAT}/${uuid}`,
                    options: {
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                let { chat, } = apiResponse.data

                this.chat = chat

            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, false)
            }
        },
        async deleteChat ({ uuid, }) {
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_REST_CHAT}/${uuid}`,
                    options: {
                        method: 'DELETE',
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                let activePinia = getActivePinia()

                // Sidebar aktualisieren
                let appUiStore = useAppUiStore(activePinia)
                appUiStore
                    .setSidebarState({ reload: true, provider: 'chat', })
                    .finally(() => {
                        appUiStore.resetSidebarState()
                    })

                // Listen-Ansicht aktualisieren
                let listStore = useChatListStore(activePinia)
                listStore.getChats({ page: 1, })

                // Reload
                this.reload = true
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, true)
            }
        },
        async updateChat ({ uuid,title, }){
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_REST_CHAT}/${uuid}`,
                    options: {
                        method: 'PATCH',
                        body: JSON.stringify({
                            title,
                        }),
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                let activePinia = getActivePinia()

                // Sidebar aktualisieren
                let appUiStore = useAppUiStore(activePinia)
                appUiStore
                    .setSidebarState({ reload: true, provider: 'chat', })
                    .finally(() => {
                        appUiStore.resetSidebarState()
                    })

                // Listen-Ansicht aktualisieren
                let listStore = useChatListStore(activePinia)
                listStore.getChats({ page: 1, })

                // Reload
                this.reload = true
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, false)
            }
        },
        async loadActiveChat () {
            this.reload = false
            let activePinia = getActivePinia()
            let userStore = useUserStore(activePinia)
            if (!this.activeUuid) {
                userStore.loggedIn && await this.getDailyLimit()

                return
            }

            await Promise.all([
                await this.getChat({ uuid: this.activeUuid, }),
                await this.getDailyLimit(),
            ])
        },

        async getDailyLimit () {
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: API_GET_DAILY_LIMIT,
                    options: {
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.dailyLimit = apiResponse.data.limit_usage ?? 0
            } catch (e) {
                if(Object.keys(this.chat).length === 0) {
                    this.chat = {
                        error: true,
                        messages: [],
                    }
                }
                this.chat['messages'].push({
                    content: e.message,
                    role: 'assistant',
                    error: true,
                })
                this.dailyLimit = 100
                this.invalidInput = true
            }
        },

        async sendMessage ({ chatUuid,message, }){
            if(!message) return

            if(typeof this.chat['messages'] === 'undefined'){
                this.chat['messages'] = []
            }

            this.chat['messages'].push({
                content: message,
                role: 'user',
            })

            let url = API_REST_CHAT
            let body = JSON.stringify({
                message,
                canonicals: this.canonicals,
            })

            if(chatUuid){
                url = `${API_REST_CHAT}/${chatUuid}/${EXT_API_CHAT_MESSAGE}`
                body = JSON.stringify({
                    message,
                })
            }

            try {
                this.sendingMessage = true

                // Api-Request senden
                let apiResponse = await fetch({
                    url,
                    options: {
                        method: 'POST',
                        body,
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.chat = mergeDeep(this.chat, {
                    ...apiResponse.data.chat,
                    subscription_url: apiResponse.data.subscription_url,
                })
                this.dailyLimit = apiResponse.data.limit_usage ?? 0
                this.canonicals = []
                this.activeUuid = this.chat.id

                // Listen-Ansicht aktualisieren
                if(!chatUuid) {
                    let activePinia = getActivePinia()
                    let listStore = useChatListStore(activePinia)
                    listStore.getChats({ page: 1, })
                }
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, false)
            } finally {
                this.sendingMessage = false
            }
        },
        activeReset (){
            this.chat = {}
            this.activeUuid = null
            this.reload = false
            this.invalidInput = false
        },
        initChat () {
            this.active = true
        },
        async reportChat ({ content, text, }) {
            let activePinia = getActivePinia()
            let contentReportStore = useContentReportStore(activePinia)
            await contentReportStore.reportContent({ content, rating: 'fehlerhaft', text, type: 'chat', })
        },
    },
})
