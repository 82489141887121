export function getInitializedApp (appId) {
    let globalThis = (1, eval)('this')

    process.env.NODE_ENV === 'test' && !appId && (appId = 'app')

    if(appId && globalThis._container) {

        appId = `a${appId}`
        
        return {
            app: globalThis._container[appId],
            router: globalThis._container[appId].config.globalProperties.$router,
            pinia: globalThis._container[appId].config.globalProperties.$pinia,
            i18n: globalThis._container[appId].config.globalProperties.$i18n,
        }
    } else if (!import.meta.env.SSR) {
        let app = {}

        try {
            app = globalThis._container[Object.keys(globalThis._container)[0]]
            app = {
                app,
                router: app.config?.globalProperties?.$router ?? null,
                pinia: app.config?.globalProperties?.$pinia ?? null,
                i18n: app.config?.globalProperties?.$i18n ?? null,
            }
        } catch { /* empty */ }

        return app
    } else {
        return {}
    }
}

export function setInitializedApp (app) {
    let globalThis = (1, eval)('this')
    let appId = app.config.globalProperties.$pinia.state.value.appId
    appId = `a${appId}`
    globalThis._container = globalThis._container || {}
    globalThis._container[appId] = app
}