import {REF_SHORT, REF_DEFAULT, useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {getActivePinia,} from 'pinia'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useBooknamesStore,} from '@/assets/js/src/modules/bible/_pinia/booknames'

/**
 * Die Hilfsfunktion löst eine canonical auf
 */
export const splitCanonical = function (canonical) {
    let book = Math.floor(canonical / 1000000)
    let chapter = Math.floor(canonical / 1000) % 1000
    let verse = canonical % 1000

    return {book, chapter, verse,}
}

/**
 * Die Hilfsfunktion löst ein Array von Canonicals zu einem TextRef-String auf
 */
export const reduceCanonicalsToRefVerses = function (canonicals,sepVerse,sepRange) {
    sepVerse = sepVerse || ''
    sepRange = sepRange || ''

    return canonicals.reduce(
        (content, canonical, index, canonicals) => {
            let verse = canonical % 1000

            if (content.length === 0) {
                return `${verse}`
            }

            let lastVerse = canonicals[index - 1] % 1000

            if (verse - lastVerse === 1) {
                return `${content.replace(sepVerse + lastVerse, '')}${sepVerse}${verse}`
            } else {
                return `${content}${sepRange}${verse}`
            }
        },
        ''
    )
}

/**
 * Die Hilfsfunktion löst ein Array von Canonicals zu einem TextRef-String auf
 */
export const reduceCanonicalsToRef = function ({canonicals, type, isUrl, booknames, abbr,}) {
    isUrl = isUrl || false
    type = type || REF_DEFAULT
    if(typeof canonicals[0] === 'undefined') {
        return
    }
    let {book, chapter,} = splitCanonical(canonicals[0])

    let activePinia = getActivePinia()
    let booknamesStore = useBooknamesStore(activePinia)
    booknames = booknames || booknamesStore.booknames

    if(!Object.keys(booknames).length || !book){
        return ''
    }

    let bookname = ''
    try {
        bookname = (type === REF_SHORT) ?
            booknames[book].abbr || (booknames[book].chapter || booknames[book].default || booknames[book]) :
            (booknames[book].chapter || booknames[book].default || booknames[book])
        // eslint-disable-next-line no-unused-vars
    } catch (e) {
        booknames = booknamesStore.booknames
        bookname = (type === REF_SHORT) ?
            booknames[book].abbr || (booknames[book].chapter || booknames[book].default) :
            (booknames[book].chapter || booknames[book].default)
    }

    let sepStore = abbr ? useBibleStore(activePinia).bibles[abbr] : useLangStore(activePinia).guiLanguage
    let {sep_book, sep_chapter, sep_verse, sep_range,} = sepStore

    let verses = reduceCanonicalsToRefVerses(canonicals,sep_verse,sep_range)
    let ref = `${bookname}${chapter  === 0  ?  '' : (isUrl ? '' : sep_book) + chapter}${verses === '0' ? '' : sep_chapter + verses}`

    return isUrl ? encodeURIComponent(ref) : ref
}

export function filterBooks ({booksEnum, content, filterKey,}) {
    filterKey = filterKey || 'chapter_canonical'

    return content.filter((entity) => {
        let {book,} = splitCanonical(entity[filterKey])

        return booksEnum.includes(book.toString())
    })
}

/**
 * Konstanten der Sotierung Optionen
 * @type {string}
 */
export const DATE_SORT = 'date'
export const CANONICAL_SORT = 'canonical'
export const CANONICAL_DEEP_SORT = 'canonical_deep'
export const ALPHABET_SORT = 'alphabet'
export const USER_COUNT_SORT = 'usercount'
export const RELEVANCE_SORT = 'relevance'
export const COLOR_SORT = 'color'
export const TYPE_SORT = 'type'
export const ASC_ORDER = 'ASC'
export const DESC_ORDER = 'DESC'
export const SORT_KEYS = {}
SORT_KEYS[DATE_SORT] = 'async.common.sort.date'
SORT_KEYS[CANONICAL_SORT] = 'async.common.sort.canonical'
SORT_KEYS[CANONICAL_DEEP_SORT] = 'async.common.sort.canonical_deep'
SORT_KEYS[ALPHABET_SORT] = 'async.common.sort.alphabet'
SORT_KEYS[USER_COUNT_SORT] = 'async.common.sort.user_count'
SORT_KEYS[RELEVANCE_SORT] = 'async.common.sort.relevance'
SORT_KEYS[COLOR_SORT] = 'async.common.sort.color'
SORT_KEYS[TYPE_SORT] = 'async.common.sort.type'


/**
 * Sortiert den üergenen Inhalt anhand der üergebenen Argumente
 *
 * @param content
 * @param sort
 * @param order
 * @param key
 * @param sortFn
 * @returns {*}
 */
export function sortContent ({content, sort, order, key, sortFn,}) {

    // SORT TYPE
    if (![ DATE_SORT,CANONICAL_SORT,ALPHABET_SORT,CANONICAL_DEEP_SORT,USER_COUNT_SORT, ].includes(sort)) {
        sort = CANONICAL_SORT
    }

    switch (sort) {
    case DATE_SORT: {
        key = key || 'updated_at'
        content.sort((entity, nextEntity) => {
            let date = new Date(entity[key])
            let nextDate = new Date(nextEntity[key])

            return date > nextDate ? -1 : date < nextDate ? 1 : 0
        })
        break
    }
    case CANONICAL_SORT: {
        key = key || 'chapter_canonical'
        content.sort((entity, nextEntity)  => {
            return entity[key] - nextEntity[key]
        })
        break
    }
    case CANONICAL_DEEP_SORT: {
        key = key || 'canonicals'
        content.sort((entity, nextEntity)  => {
            return ((entity[key] || {})[0] || entity['chapter_canonical']) - ((nextEntity[key] || {})[0] || nextEntity['chapter_canonical'])
        })
        break
    }
    case ALPHABET_SORT: {
        key = key || 'key'
        content.sort((entity, nextEntity)  => {
            return entity[key].localeCompare(nextEntity[key])
        })
        break
    }
    case USER_COUNT_SORT:  {
        if(typeof sortFn === 'function') {
            content.sort(sortFn)
        }
        break
    }

    }

    // SORT DIRECTION
    if (![ ASC_ORDER, DESC_ORDER, ].includes(order)) {
        order = ASC_ORDER
    }

    if(order === DESC_ORDER) {
        content.reverse()
    }

    return content
}

/**
 * Debounce helper
 *
 * @param fn
 * @param delay
 * @returns {Function}
 */
export function debounce (fn, delay) {
    var timeoutID = null

    return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}

export function throttle (fn, delay) {
    let timeoutID = null
    let throttlePause

    return function () {
        if (throttlePause) return
        throttlePause = true
        clearTimeout(timeoutID)
        let args = arguments
        let that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
            throttlePause = false
        }, delay)
    }
}

export async function initHeadroom (store) {
    const {default: Headroom,} = await import('headroom.js')

    let appUiStore = useAppUiStore(store)
    let userStore = useUserStore(store)
    let autocompleteStore = useAutocompleteStore(store)

    let unpinRelElems = ({pinClass, unpinClass,}) => {
        let classes = globalThis.clone(appUiStore.mainHeaderClasses)
        classes[unpinClass] = true
        classes[pinClass] = false
        appUiStore.mainHeaderClasses = classes
    }

    let pinRelElems = ({pinClass, unpinClass,}) => {
        let classes = globalThis.clone(appUiStore.mainHeaderClasses)
        classes[unpinClass] = false
        classes[pinClass] = true
        appUiStore.mainHeaderClasses = classes
    }

    let topRelElems = ({topClass,}) => {
        let classes = globalThis.clone(appUiStore.mainHeaderClasses)
        classes[topClass] = true
        appUiStore.mainHeaderClasses = classes
    }

    let notTopRelElems = ({topClass,}) => {
        let classes = globalThis.clone(appUiStore.mainHeaderClasses)
        classes[topClass] = false
        appUiStore.mainHeaderClasses = classes
    }

    let isFixedHeader = () => {
        return userStore.user.options.fixHeader && !appUiStore.mobileView
    }

    let headroom = new Headroom(document.querySelector(".bs-header-wrapper"), {
        offset: 90,
        tolerance: 10,
        classes : {
            // when element is initialised
            initial: "main-header",
            // when scrolling up
            pinned: "main-header--pinned",
            // when scrolling down
            unpinned: "main-header--unpinned",
            // when above offset
            top : "main-header--top",
        },
        onPin: function () {
            if(!appUiStore.blockHeadroom && !isFixedHeader()){
                pinRelElems({
                    pinClass:this.classes.pinned,
                    unpinClass:this.classes.unpinned,
                })
            } else {
                // Also don't pin the main headroomEl
                this.elem.classList.add(this.classes.unpinned)
                this.elem.classList.remove(this.classes.pinned)
            }
        },
        onUnpin: function () {
            // Don't change header on followed use case
            if ( autocompleteStore.isComponentVisible || appUiStore.overlay || appUiStore.home) {
                this.elem.classList.remove(this.classes.unpinned)
                this.elem.classList.add(this.classes.pinned)
                if(autocompleteStore.isComponentVisible) {
                    pinRelElems({
                        pinClass:this.classes.pinned,
                        unpinClass:this.classes.unpinned,
                    })
                }

                return
            }
            if(!appUiStore.blockHeadroom && !isFixedHeader()){
                unpinRelElems({
                    pinClass:this.classes.pinned,
                    unpinClass:this.classes.unpinned,
                })
            } else {
                // Also don't unpin the main headroomEl
                this.elem.classList.remove(this.classes.unpinned)
                this.elem.classList.add(this.classes.pinned)
            }
        },
        onTop: function () {
            if(!appUiStore.blockHeadroom && !isFixedHeader()){
                topRelElems({
                    topClass:this.classes.top,
                })
            } else {
                // Also don't top the main headroomEl
                this.elem.classList.remove(this.classes.top)
                this.elem.classList.add(this.classes.notTop)
            }
            if(appUiStore.home && appUiStore.backgroundFade){
                this.elem.classList.add('bs-home__header-wrapper')
                this.elem.classList.add('bs-home__header-wrapper--top')
                this.elem.classList.add(this.classes.top)
                this.elem.classList.remove(this.classes.notTop)
            }else if(appUiStore.backgroundFade || !appUiStore.home){
                this.elem.classList.remove('bs-home__header-wrapper')
                this.elem.classList.remove('bs-home__header-wrapper--top')
            }
        },
        onNotTop: function () {
            if(!appUiStore.blockHeadroom && !isFixedHeader()){
                notTopRelElems({
                    topClass:this.classes.top,
                })
            } else {
                // Also don't untop the main headroomEl
                this.elem.classList.add(this.classes.top)
                this.elem.classList.remove(this.classes.notTop)
            }

            if(appUiStore.home && appUiStore.backgroundFade){
                this.elem.classList.add('bs-home__header-wrapper')
                this.elem.classList.remove('bs-home__header-wrapper--top')
                this.elem.classList.add(this.classes.notTop)
                this.elem.classList.remove(this.classes.top)
            }else if(appUiStore.backgroundFade || appUiStore.home){
                this.elem.classList.remove('bs-home__header-wrapper')
                this.elem.classList.remove('bs-home__header-wrapper--top')
            }
        },
    })
    headroom.init()
    window.MAIN_HEADROOM = headroom
}

export function animateScroll (to) {
    let pos = window.pageYOffset
    let step = 20
    let nextStep = window.pageYOffset

    let interval = setInterval(() => {
        if(pos >= to){
            nextStep -= step

            if(nextStep <= to){
                nextStep = to
            }
        }else{
            nextStep += step

            if(nextStep >= to){
                nextStep = to
            }
        }

        window.scrollTo(0,nextStep)

        if(nextStep === to){
            clearInterval(interval)
        }
    },2)
}
