import {watch,} from 'vue'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {getActivePinia, storeToRefs,} from 'pinia'

let globalThis = (1, eval)('this')
export function useUserOptionSyncWithSessionStorage () {
    let userStore = useUserStore(getActivePinia())
    let {user,} = storeToRefs(userStore)

    watch(()=>user.value.options,
        (newValue)=>{
            let sOptions = globalThis.clone(newValue)
            sOptions.confirmed && delete sOptions.confirmed
            try {
                window.sessionStorage.setItem('options', JSON.stringify(sOptions))
                // eslint-disable-next-line no-unused-vars
            } catch (e) {
                try {
                    window.sessionStorage.clear()
                    window.sessionStorage.setItem('options', JSON.stringify(sOptions))
                } catch { /* empty */ }
            }
        },
        {immediate: !import.meta.env.SSR,})
}
