import { defineStore, } from 'pinia'
import fetch from '@/assets/js/src/util/fetch'
import { getDefaultHeaders, } from '@/assets/js/src/util/fetch/defaultHeaders'
import { checkResponse, handleException, } from '@/assets/js/src/util/apiTools'
import { mergeDeep, } from '@/assets/js/src/util/merge'

const API_GET_ASYNC_MESSAGES = '/api/lang/async'
export const DIR_RTL = 'rtl'
export const DIR_LTR = 'ltr'

export const useLangStore = defineStore('lang', {
    state: () => {
        return {
            locale: 'de',
            localeDirection: DIR_LTR,
            rtl: false,
            fallbackLocale: 'en',
            sepChapters: [],
            messages: {},
            guiLanguages: [],
            supportedLocales: [],
        }
    },
    getters: {
        guiLanguage: (state) => state.guiLanguages.find((language) => language.locale === state.locale),
        getLocaleById: (state) => {
            return (id) =>
                state.guiLanguages.find((language) => language.id === id).locale
        },
        areAsyncKeysLoaded: (state) => {
            return (keys) => {
                if (typeof state.messages[state.locale]?.async !== 'undefined') {
                    return !keys.some((key) => typeof state.messages[state.locale].async[key] === 'undefined')
                } else {
                    return false
                }
            }
        },
        commonKeysLoaded () {
            return this.areAsyncKeysLoaded([ 'common', ])
        },
        guiLanguagesEnum: (state) => {
            let enums = []
            state.guiLanguages.forEach((language) => {
                enums.push({ title: language.name, value: language.id, })
            })

            return enums
        },
        guiLanguageName: (state) => state.guiLanguages.find((guiLang) => guiLang.locale === state.locale)?.name,
        localeSepBook: (state) => state.guiLanguages.find((language) => language.locale === state.locale)?.sep_book,
        isDeLocale: (state) => state.locale === 'de',
        isEnLocale: (state) => state.locale === state.fallbackLocale,
    },
    actions: {
        async fetchAsyncMessages ({ keys, locale, noLoadingBar, }) {
            noLoadingBar = noLoadingBar || false

            try {
                // Das übergebene Array zu Query-String umwandeln
                let params = keys.reduce((accumulator, currentValue, currentIndex) => {
                    return accumulator + ((currentIndex === 0) ? '?' : '&') + 'keys[]=' + encodeURIComponent(currentValue)
                }, '')

                locale = locale || this.locale
                let useCache = true

                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_GET_ASYNC_MESSAGES}${params}${locale ? '&locale=' + locale : ''}`,
                    options: {
                        headers: getDefaultHeaders({
                            useCache,
                        }),
                    },
                    loading: !noLoadingBar,
                    useCache,
                    tags: [
                        `locale.${locale}`,
                    ],
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                if(Array.isArray(this.messages[locale])) {
                    this.messages[locale] = {}
                }

                let messages = globalThis.clone(this.messages[locale] || {})
                messages = mergeDeep(messages, apiResponse.data[locale])
                this.messages[locale] = messages
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true)
            }
        },
    },
})
