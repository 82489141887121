import { onMounted, ref, useTemplateRef, watch, } from 'vue'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper.js'
import { getActivePinia, storeToRefs, } from 'pinia'
import { useAppUiStore, } from '@/assets/js/src/pinia/appUi.js'

export const useTooltip = ({ cookieVar, templateRef, onHeader, }) => {

    const activePinia = getActivePinia()
    const { countRouteChange, mainHeaderClasses, } = storeToRefs(useAppUiStore(activePinia))
    const showTooltip = ref(false)
    const initialized = ref(false)
    onMounted(() => {
        setTimeout(() => {
            initialized.value = true
            showTooltip.value = !cookieManager.get(cookieVar)
        }, 5000)
    })

    const setCookie = () => {
        if (!cookieManager.get(cookieVar)) {
            cookieManager.set(cookieVar, 'true', { expires: 365, })
        }
        showTooltip.value = false
    }

    const tooltip = useTemplateRef(templateRef)
    watch(countRouteChange, ()=>{
        setTimeout(()=>{
            tooltip.value?.updateLocation && tooltip.value.updateLocation()
        },100)
    })

    if(onHeader) {
        watch(mainHeaderClasses,(newValue)=>{
            if(!initialized.value || cookieManager.get(cookieVar)) {
                return
            }

            showTooltip.value = !newValue['main-header--unpinned']
        })
    }
    
    return {
        setCookie,
        showTooltip,
    }
}
