import { getActivePinia, } from 'pinia'
import { useLangStore, } from '@/assets/js/src/modules/lang/_pinia/lang'
import { useUserStore, } from '@/assets/js/src/modules/user/_pinia/user'
import { useSSRContext, } from 'vue'

export function getDefaultHeaders ({ useCache, }) {
    let activePinia = getActivePinia()
    let langStore = useLangStore(activePinia)
    let headers = {}
    
    if (import.meta.env.SSR) {
        const ctx = useSSRContext()

        headers['x-user-agent'] = ctx.headers.xUserAgent
        headers['x-forwarded-for'] = ctx.headers.xForwardedFor
        headers['x-forwarded-host'] = ctx.headers.xForwardedHost
        headers['x-forwarded-proto'] = ctx.headers.xForwardedProto
        headers['x-forwarded-port'] = ctx.headers.xForwardedPort
    }

    headers['X-Requested-With'] = 'XMLHttpRequest'
    headers['x-locale'] = langStore.locale
    
    if(!useCache) {
        let userStore = useUserStore(activePinia)
        let jwtToken = userStore.jwt

        if (typeof jwtToken !== 'undefined' && jwtToken.trim() !== '') {
            headers['Authorization'] = 'Bearer ' + jwtToken
        }
    }

    return headers
}
