import { getInitializedApp, } from '@/assets/js/src/util/appRef'
import { useShowMessage, } from '@/assets/js/src/modules/snackbar/_composables/useShowMessage'
import { ApiResponseError, BsNotFoundError, } from '@/assets/js/src/util/bugsnag'
import { Logger, } from '@/assets/js/src/util/logger'

export const CANCELED_API_CALL = 'redundant_api_call'

const handleException = function (
    exception,
    throwException = false,
    showSnackbar = undefined
) {
    showSnackbar ??= !throwException
    showSnackbar && handleSnackbar(exception)

    Logger.error(exception)

    if (throwException || exception.payload?.data !== undefined) {
        throw exception
    }
}

const handleSnackbar = function (exception) {
    if (typeof exception.payload === 'undefined') {
        commitError(exception.message)

        return
    }

    if (exception.payload.message) {
        commitError(exception.payload.message)

        return
    }
    
    try {
        if (![ 404, 403, ].includes(exception.payload.status)) {
            let msg = ''
            if (typeof exception.payload === 'object') {
                for (const [ key, value, ] of Object.entries(exception.payload)) {
                    if (isNaN(key)) {
                        msg += key + ': ' + value + '<br>'
                    } else {
                        msg += value + '<br>'
                    }
                }
            } else {
                msg = JSON.stringify(exception.payload).replace(/(\{|\})/g, '')
            }
            commitError(msg)
        }
        // eslint-disable-next-line no-unused-vars
    } catch (e) {
        commitError()
    }
}

const checkResponse = function (apiResponse) {
    if (!apiResponse) {
        throw new ApiResponseError('no response')
    }
    // Überprüfe auf Fehler und werfe Exception
    if (checkResponseKey(apiResponse, 'error')) {
        // ErrorResponse vom Server behandeln
        if ([ 404, 403, 400, ].includes(apiResponse.error.status)) {
            throw new BsNotFoundError(apiResponse.error)
        }

        if (import.meta.env.DEV) {
            Logger.debug({
                devInfo: apiResponse.error.dev_info,
                apiResponse,
            })
        }

        if (import.meta.env.MODE === 'test') {
            throw new BsNotFoundError({ data:'', })
        }

        throw new Error(apiResponse.error.message)

    } else if (
        // SuccessResponse mit Fehlermeldungen vom Server (z.B. Formulare) behandeln
        checkResponseKey(apiResponse, 'data') &&
        checkResponseKey(apiResponse.data, 'errors')
    ) {
        // FormType Fehler
        if (import.meta.env.DEV) {
            Logger.debug({
                errors: apiResponse.data.errors,
                apiResponse,
            })
        }
        throw {
            exception: new Error(apiResponse.message),
            payload: apiResponse.data.errors,
        }
    }

    // SuccessResponse ohne Fehlermeldungen
    if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
        Logger.debug({
            apiResponse,
        })
    }
    let status = apiResponse?.status >= 400
    let data = checkResponseKey(apiResponse, 'data')

    return status && data
}

const checkResponseKey = function (apiResponse, key) {
    return (
        apiResponse &&
        Object.prototype.hasOwnProperty.call(apiResponse, key) &&
        Object.keys(apiResponse[key]).length !== 0
    )
}

const commitMessage = function (apiResponse, type = 'success') {
    if (!checkResponseKey(apiResponse, 'message')) {
        return
    }

    useShowMessage().showMessage({ message: apiResponse.message, type: type, })
}

const commitError = function (errorMsg = 'Fehler') {
    if (!import.meta.env.SSR) {
        let { i18n, } = getInitializedApp()
        if (errorMsg === 'Failed to fetch' || errorMsg.length === 0) {
            errorMsg = i18n && i18n.t('async.common.standard_error')
        }

        useShowMessage().showMessage({ message: errorMsg, type: 'error', html: !!errorMsg.match(/(<*>)/g), })
    }
}

const startLoadingIndicator = function () {
    if (!(window.bsNProgress && window.bsNProgress.isStarted())) {
        window.bsNProgress && window.bsNProgress.configure({
            trickleSpeed: 150,
            minimum: 0.2,
            showSpinner: false,
        })
        window.bsNProgress && window.bsNProgress.start()
    } else {
        window.bsNProgress && window.bsNProgress.set(0.5)
    }
}

const stopLoadingIndicator = function () {
    if (window.bsNProgress && window.bsNProgress.isStarted() && process.env.NODE_ENV !== 'test') {
        window.bsNProgress && window.bsNProgress.done()
    }
}

const paramsToQuery = function (obj, prefix) {
    let str = [],
        p
    for (p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
            let k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p]
            str.push((v !== null && typeof v === 'object') ?
                paramsToQuery(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v))
        }
    }

    return str.join('&')
}

const compareUrl = function (url, compareTo) {
    return url === compareTo ||
        url === compareTo.split('/').map((part) => encodeURIComponent(part)).join('/') ||
        false
}

export {
    paramsToQuery,
    checkResponse,
    handleException,
    commitMessage,
    commitError,
    startLoadingIndicator,
    stopLoadingIndicator,
    compareUrl,
}
