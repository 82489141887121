import { defineStore, getActivePinia, } from 'pinia'
import { DATE_SORT, DESC_ORDER, RELEVANCE_SORT, TYPE_SORT, } from '@/assets/js/src/util/uiTools'
import fetch from '@/assets/js/src/util/fetch'
import { getDefaultHeaders, } from '@/assets/js/src/util/fetch/defaultHeaders'
import { checkResponse, handleException, paramsToQuery, } from '@/assets/js/src/util/apiTools'
import { useUserStore, } from '@/assets/js/src/modules/user/_pinia/user'

/** API ROUTEN **/
export const API_REST_ACTIVITY_ADD = '/api/activity'
export const API_GET_ALL_ACTIVITIES = '/api/activities'
export const API_CLEAR_ALL_ACTIVITIES = '/api/activities'
export const API_GET_ACTIVITY_ABBRS = '/api/activity/abbrs'

export const useActivityStore = defineStore('activity', {
    state: () =>({
        // Sort - Params
        order: DESC_ORDER,
        sort: DATE_SORT,
        reloading: false,
        reload: false,

        // Filter - Params
        search: '',
        types: [ 'text', 'search', ],
        date: '',

        // Content - Meta
        responseMeta: {},
        page: 1,
        limit: 100,
        routeMeta: {
            abbreviations: '',
            name: '',
        },

        // Content - List
        activities: [],

        // Cache - Options
        mode: null,
    }),
    getters: {
        maxPages: (state) => state.responseMeta.max_page,
    },
    actions: {
        setSort (payload) {
            if ([
                TYPE_SORT,
                DATE_SORT,
                RELEVANCE_SORT,
            ].includes(payload)) {
                this.sort = payload
            } else {
                this.sort = DATE_SORT
            }
            this.reload = true
        },
        setTypes (payload) {
            if (this.types === payload) {
                return
            }
            this.types = payload
            this.reload = true
        },
        setSearch (payload) {
            if (this.search === payload) {
                return
            }
            this.search = payload
            this.reload = true
        },
        setDate (payload) {
            if (this.date === payload) {
                return
            }
            this.date = payload
            this.reload = true
        },
        async addActivity ({ name, route, type, abbreviations, parent, }) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value

            if (!name.length) {
                return
            }

            try {

                let bibles = abbreviations
                abbreviations = abbreviations.join(',')

                let lastRouteMeta = globalThis.clone(this.routeMeta)

                if (parent === 0) {
                    let biblesOld = lastRouteMeta.abbreviations.split(',')

                    let diff = bibles.filter(elem => biblesOld.indexOf(elem) === -1)
                    let diff2 = biblesOld.filter(elem => bibles.indexOf(elem) === -1)

                    if ((diff.length !== 0 || diff2.length !== 0) && name === name) {
                        parent = globalThis.clone(rootState.user.parentActivity)
                    }
                }

                this.routeMeta = {
                    abbreviations: abbreviations,
                    name: name,
                }

                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_REST_ACTIVITY_ADD}`,
                    options: {
                        method: 'POST',
                        body: JSON.stringify({
                            name,
                            route,
                            type,
                            abbreviations,
                            parent,
                        }),
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                let userStore = useUserStore(activePinia)
                userStore.parentActivity = apiResponse.data


            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, true)
            }
        },
        async getActivityAbbrs () {
            try {

                // Api-Request senden
                let apiResponse = await fetch({
                    url: API_GET_ACTIVITY_ABBRS,
                    options: {
                        headers: getDefaultHeaders({}),
                    },
                    loading: false,
                })
                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                return apiResponse
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, false, false)
            }
        },
        async getActivities ({ page, loadingAnimation = true, }) {
            page = page || 1

            if (loadingAnimation) {
                this.reloading = true
            }
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_GET_ALL_ACTIVITIES}?${paramsToQuery({
                        filter: JSON.stringify({
                            search: this.search,
                            types: this.types,
                            date: this.date,
                        }),
                        sort: this.sort,
                        page,
                        limit: this.limit,
                        mode: this.mode,
                    })}`,
                    options: {
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                const { activities, ...responseMeta } = apiResponse.data

                this.page = page
                this.mode = null
                if (page === 1) {
                    this.activities = activities
                    this.responseMeta = responseMeta
                } else {
                    this.activities.push(...activities)
                }
                this.reloading = false
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, true)
            }
        },
        async clearActivity () {
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_CLEAR_ALL_ACTIVITIES}`,
                    options: {
                        method: 'DELETE',
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }
                this.page = 1
                this.activities = []
                this.responseMeta = {}
                this.types = [ 'text', 'search', ]
                this.search = ''
                this.date = ''
                this.mode = 'put'

            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, true)
            }
        },
    },
})
