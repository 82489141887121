import {getCurrentInstance,} from "vue"
import {Logger,} from '@/assets/js/src/util/logger'

export function useAnalytics () {
    const {proxy,} = getCurrentInstance()
    const ssrHint = (o) => {
        Logger.error({
            o,
            error: new Error('Analytics not available in SSR'),
        })
    }

    return {
        bsa: import.meta.env.SSR
            ? {
                event: ssrHint,
                page: ssrHint,
                link: ssrHint,
            }
            : proxy.$bsa,
    }
}
