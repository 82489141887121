import {defineStore,} from 'pinia'
import {useStripHtml,} from '@/assets/js/src/util/composables/useStripHtml'

const SNACKBAR_TYPES = [ 'info', 'success', 'warning', 'error', 'help', ]
export const DEFAULT_TYPE = 'success'
const DEFAULT_ID = 'default'
export const DEFAULT_TIMEOUT = 8000

export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({
        snackbars: [],
    }),
    actions: {
        async showMessage ({message, type, timeout, html, id, routes, closeCb,}) {
            if(typeof message === 'undefined') {
                return
            }
            routes = routes || []
            // Set known type
            if (!SNACKBAR_TYPES.includes(type)) {
                type = DEFAULT_TYPE
            }

            // Set timeout
            if (typeof timeout === 'undefined') {
                timeout = DEFAULT_TIMEOUT
            }

            html = html || false

            await this.addSnackbar({
                message,
                type,
                timeout,
                routes,
                closeCb,
                html,
                id: id || DEFAULT_ID,
                uniqueId: Date.now().toString(36) + Math.random().toString(36).substr(2),
                show: true,
            })
        },

        async addSnackbar (newSnackbar) {
            // Immer nur die neue Snackbar mit gleicher ID (Ausnahme DEFAULT_ID) zeigen
            if (newSnackbar.id !== DEFAULT_ID) {
                for (let i = 0; i < this.snackbars.length; i++) {
                    if (this.snackbars[i].id === newSnackbar.id) {
                        this.removeSnackbarByUniqueId({uniqueId: this.snackbars[i].uniqueId,})
                    }
                }
            }

            // Finde ein Duplikat
            let duplicate = this.snackbars.find((snackbar) => {
                // Snackbar hat die gleiche message
                let {stripHtml,} = useStripHtml()

                return stripHtml(snackbar.message) === stripHtml(newSnackbar.message)
            })

            // Wenn kein Duplikat gefunden, dann neue Snackbar hinzufügen
            if (!duplicate) {
                this.snackbars = [
                    newSnackbar,
                    ...this.snackbars,
                ]
            }
        },

        removeSnackbarByUniqueId ({uniqueId,}) {
            let found = this.snackbars.find((snackbar) => snackbar?.uniqueId === uniqueId)
            if (found) {
                this.snackbars.splice(this.snackbars.indexOf(found), 1)
            }
        },

        removeSnackbarById ({id,}) {
            let found = this.snackbars.find((snackbar) => snackbar?.id === id)
            if (found) {
                this.snackbars.splice(this.snackbars.indexOf(found), 1)
            }
        },
    },
})