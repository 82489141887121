import '@/assets/js/src/util/declareglobals.client'
import {setupApp,} from '@/assets/js/src/main'
import {errorHandler,} from '@/assets/js/src/util/bugsnag'
import {initBSA,} from '@/assets/js/src/util/analytics'
import {DEFAULT_TYPE, useSnackbarStore,} from '@/assets/js/src/modules/snackbar/_pinia/snackbar'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {createWebHistory,} from "vue-router"
import {createApp, h,} from "vue"
import App from "@/assets/js/src/App.vue"

const { app, router, pinia, } = setupApp({
    history: createWebHistory(),
    app: createApp({
        render: () => h(App),
    }),
})

// Analytics
initBSA({ app, router, pinia,})

// Error Handling
app.config.errorHandler = errorHandler

app.config.performance = import.meta.env.DEV

/**
 * Löst Abhängigkeiten in Bezug auf die aktuelle route
 *
 * ist vor allem für die Hydration (ServerSSR_HTMLOutput === ClientJS_HTMLOutput) wichtig!
 */
router.isReady().then(function () {
    let appEl = document.getElementById('app')
    app
        .mount(appEl)
        .$nextTick(() => {
            let perfSnackbar = document.getElementById("ampHint")
            if(perfSnackbar) {
                if(10200 === parseInt(window.getComputedStyle(perfSnackbar).zIndex)) {
                    setTimeout(() => {
                        perfSnackbar && perfSnackbar.parentNode && perfSnackbar.parentNode.removeChild(perfSnackbar)
                    },10000)
                } else {
                    perfSnackbar && perfSnackbar.parentNode && perfSnackbar.parentNode.removeChild(perfSnackbar)
                }
            }
            window.bsNProgress && window.bsNProgress.done()
            let appUiStore = useAppUiStore(pinia)
            try {
                let options = window.sessionStorage.getItem('options')
                if(options) {
                    options = JSON.parse(options)

                    appUiStore.sidebarOpen = options?.sidebar?.open || false
                }
            } catch (err) {
                appUiStore.sidebarOpen = false
            }

            // Check Snackbar Message on stateProvider -> params
            let routeMetaStore = useRouteMetaStore(pinia)
            if(Object.keys(routeMetaStore.params).length !== 0) {
                let snackbarContent = routeMetaStore.params.snackbar
                if(typeof snackbarContent !== 'undefined') {
                    let {message,type,} = snackbarContent
                    let snackbarStore = useSnackbarStore(pinia)
                    snackbarStore.showMessage({
                        message,
                        type,
                    }).then(() => {
                        routeMetaStore.deleteParams()
                    })

                    // Send Registration Success Event
                    let currentRoute = router.currentRoute.value || router.currentRoute || {}
                    if(currentRoute.name === 'ProfileRoute' && type === DEFAULT_TYPE) {
                        let userStore = useUserStore(pinia)
                        let $bsa = app?.config?.globalProperties?.$bsa
                        $bsa && $bsa.event({
                            eventCategory: 'goals',
                            eventAction: 'registration confirmed',
                            eventValue: JSON.stringify(userStore.user?.options?.promo),
                        })
                    }
                }
            }
        })

    let el = document.activeElement
    appEl.replaceWith(appEl.children[0])
    el.focus()
})
