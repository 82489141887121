import {Logger,} from "@/assets/js/src/util/logger"
import {Perf,} from "@/assets/js/src/util/performance"

export default async function ({url, options, loading, useCache, tags, includeTagsInId,}) {
    if (import.meta.env.DEV && import.meta.env.MODE !== 'test' && (url || options)) {
        Logger.debug({
            url,
            options,
        })
    }

    options = options || {}
    if (typeof options.headers === 'undefined') {
        options.headers = {}
    }

    tags = tags || []
    includeTagsInId = includeTagsInId || false

    if (typeof loading == 'undefined') {
        loading = true
    }
    useCache = (import.meta.env.PROD && useCache) || false

    if (!import.meta.env.SSR) {
        const {default: clientFetch,} = await import('@/assets/js/src/util/fetch/clientFetch')

        return clientFetch({url, options, loading, useCache, tags, includeTagsInId,})
    } else {
        let apiCallId = hashCode(url)
        Perf.start(apiCallId)

        if(process.env.FETCH_MODE === 'console'){
            return import('@/assets/js/src/util/fetch/nodeCommandFetch')
                .then(({default: nodeCommandFetch,}) => {
                    return nodeCommandFetch({url, options, useCache, tags, includeTagsInId,}).finally(()=>{
                        Perf.end(apiCallId)
                    })
                })
        }else{
            return import('@/assets/js/src/util/fetch/nodeFetch')
                .then(({default: nodeFetch,}) => {
                    return nodeFetch({url, options, useCache, tags, includeTagsInId,}).finally(()=>{
                        Perf.end(apiCallId)
                    })
                })
        }
    }
}

let fetchCount = 0

const hashCode = (str) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash
    }

    return 'api->php->' + hash + '_' + fetchCount++
}
