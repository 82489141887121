import fetch from '@/assets/js/src/util/fetch'
import { checkResponse, handleException, paramsToQuery, } from '@/assets/js/src/util/apiTools'
import { safeDecodeURI, } from '@/assets/js/src/util/safeDecodeURI'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import { safeEncodeURI, } from '@/assets/js/src/util/safeEncodeURI'
import { getDefaultHeaders, } from '@/assets/js/src/util/fetch/defaultHeaders'
import { useAutocompleteStore, } from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import { defineStore, getActivePinia, } from 'pinia'
import { useRouteMetaStore, } from '@/assets/js/src/pinia/routeMeta'
import { useAppUiStore, } from '@/assets/js/src/pinia/appUi'
import { useBibleStore, } from '@/assets/js/src/modules/bible/_pinia/bible.js'

const API_PREFIX_SEARCH = '/api/search'

export const HYBRID = 'hybrid'
export const CLASSIC = 'classic'
export const COHERENCE_VALUES = [ 100, 70, 50, 30, 1, ]

const defaultSearchType = {
    type: HYBRID,
}

export const defaultOptionsClassic = {
    fullwords: true,
    umlaute: false,
    exactPhrase: false,
    searchHeadings: false,
    area: [],
}

export const defaultOptionsSmart = {
    coherence: 2,
    area: [],
}

export const defaults = {
    ...defaultSearchType,
    ...defaultOptionsClassic,
    ...defaultOptionsSmart,
}

const defaultsHash = JSON.stringify(defaults)

export const useSearchStore = defineStore('search', {
    state: () => ({
        searchResult: {},
        ...defaults,
        page: 1,
        exceeded: false,
        canonical: 0,
        lastCanonical: 0,
        offsets: {},
        active: false,
    }),
    getters: {
        areaForApi: (state) => {
            let set = new Set(state.area)
            let atNumbers = state.area.filter(booknumber => parseInt(booknumber) >= 1 && parseInt(booknumber) <= 39)
            if (atNumbers.length === 39) {
                atNumbers.forEach(booknumber => set.delete(booknumber))
                set.add('at')
            }
            let ntNumbers = state.area.filter(booknumber => parseInt(booknumber) >= 40 && parseInt(booknumber) <= 66)
            if (ntNumbers.length === 27) {
                ntNumbers.forEach(booknumber => set.delete(booknumber))
                set.add('nt')
            }
            let akNumbers = state.area.filter(booknumber => parseInt(booknumber) >= 90 && parseInt(booknumber) <= 96)
            if (akNumbers.length === 7) {
                akNumbers.forEach(booknumber => set.delete(booknumber))
                set.add('ak')
            }

            return Array.from(set)
        },
        maxPages: (state) => state.searchResult.max_pages,
        options: (state) => {
            return Object
                .keys(defaults)
                .reduce((acc, key) => {
                    acc[key] = state[key]

                    return acc
                }, {})
        },
        optionsHash: (state) => {
            return JSON.stringify(state.options)
        },
        optionsSmartHash: (state) => {
            return JSON.stringify(Object
                .keys(defaultOptionsSmart)
                .reduce((acc, key) => {
                    acc[key] = state[key]

                    return acc
                }, {}))
        },
        isNotDefault (state) {
            if(state.isHybridSearch) {
                return JSON.stringify(defaultOptionsSmart) !== state.optionsSmartHash
            }

            return defaultsHash !== state.optionsHash
        },
        optionsAsJsonString: (state) => {
            let filter = state.optionsHash
            if (filter !== defaultsHash) {
                return JSON.stringify({ filter, })
            } else {
                return null
            }

        },
        optionsAsParams (state) {
            return paramsToQuery(JSON.parse(state.optionsAsJsonString || '{}'))
        },
        isHybridSearch: (state) => state.type === HYBRID,
    },
    actions: {
        appendSearchResult (result) {
            Object.keys(result).forEach((abbreviation) => {
                Object.keys(result[abbreviation].marks).forEach((verseId) => {
                    this.searchResult.result[abbreviation].marks[verseId] = result[abbreviation].marks[verseId]
                })
                this.searchResult.result[abbreviation].verses.push(...result[abbreviation].verses)
                Object.keys(result[abbreviation].booknames).forEach((bookNumber) => {
                    if (typeof this.searchResult.result[abbreviation].booknames[bookNumber] === 'undefined') {
                        this.searchResult.result[abbreviation].booknames[bookNumber] = result[abbreviation].booknames[bookNumber]
                    }
                })
            })
        },
        setOptions (options) {
            for(let option in options) {
                this[option] = options[option]
            }
        },
        setOptionsFromParams (params) {
            if (!params.filter) {
                return
            }
            params = JSON.parse(params.filter)
            for(let option in defaults) {
                this[option] = typeof params[option] !== 'undefined' ? params[option] : this[option]
            }
        },

        async fetchSearch ({ canonical, page, route, searchTerm, }) {
            try {
                let activePinia = getActivePinia()

                this.active = true
                let params = route.params
                let abbr = ((typeof params.abbreviation !== 'undefined') ? params.abbreviation : '') + ((typeof params.rest !== 'undefined') ? '.' + params.rest : '')

                if (!abbr) {
                    let bibleStore = useBibleStore(activePinia)
                    abbr = encodeURIComponent(bibleStore.selectedBibles.join('.'))
                }

                let search = searchTerm || params.slug || ''
                Array.isArray(search) && (search = search.join())

                if (!search.length) {
                    return
                }

                let routeMetaStore = useRouteMetaStore(activePinia)

                let newRoute = `/search/${safeEncodeURI(abbr)}/${safeEncodeURI(search)}`

                // Parameter aus routeMeta übernehmen
                if (Object.keys(routeMetaStore.params).length !== 0) {
                    this.setOptionsFromParams(routeMetaStore.params)
                    routeMetaStore.deleteParams()
                }

                if (canonical === 0) {
                    this.exceeded = false
                }

                // Exakte Phrase prüfen
                if (this.exactPhrase) {
                    search = `"${search.replace(/^"|"$/gm, '')}"`
                }

                if (!import.meta.env.SSR) {
                    let appUiStore = useAppUiStore(activePinia)
                    if (appUiStore.mobileView && !appUiStore.mobileViewLandscape) {
                        abbr = abbr.split('.').slice(0, 1).join('.')
                    } else if (appUiStore.mobileView && appUiStore.mobileViewLandscape) {
                        abbr = abbr.split('.').slice(0, 2).join('.')
                    }
                }

                let rootState = activePinia.state.value

                let url = `${API_PREFIX_SEARCH}/${this.type === HYBRID && !/^"|"$/gm.test(search) ? HYBRID : CLASSIC}`

                // Api-Request senden
                let apiResponse = await fetch({
                    url,
                    options: {
                        method: 'POST',
                        body: JSON.stringify({
                            search,
                            abbr,
                            coherence: COHERENCE_VALUES[this.coherence],
                            area: this.areaForApi,
                            fullwords: this.fullwords,
                            umlaute: this.umlaute,
                            searchHeadings: this.searchHeadings,
                            offsets: page === 1 ? {} : this.offsets,
                            canonical,
                            page,
                        }),
                        headers: getDefaultHeaders({}),
                    },
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.type = url.split('/')[3] === HYBRID
                    ? HYBRID
                    : CLASSIC

                let appUiStore = useAppUiStore(getActivePinia())

                let countRoutes = [ ...new Set(appUiStore.lastRoutes), ].length
                countRoutes > 1 && this.sendBsaEvent(search)

                // Die search-Options im Cookie setzen
                if (this.optionsAsJsonString) {
                    cookieManager.set('bs-params', this.optionsAsJsonString, {
                        secure: true,
                    })
                } else if (cookieManager.get('bs-params')) {
                    cookieManager.remove('bs-params')
                }

                this.lastCanonical = apiResponse.data?.last_canonical ?? 0
                this.page = apiResponse.data.page

                let count = Object.values(apiResponse.data.result).reduce((carry, value) => carry + parseInt(value.count), 0)

                if (page === 1) {
                    this.searchResult = apiResponse.data
                } else if (count > 0) {
                    this.appendSearchResult(apiResponse.data.result)
                } else {
                    this.exceeded = true
                }

                this.offsets = apiResponse.data.offsets ?? {}
                this.canonical = canonical

                // Such-Options einlenden
                let autocompleteStore = useAutocompleteStore(getActivePinia())
                autocompleteStore.isSearch = true

                // Auch RouteMeta laden
                if (!import.meta.env.SSR && (safeDecodeURI(newRoute) !== rootState.routeMeta.canonical)) {
                    routeMetaStore.loadRouteMeta({
                        url: newRoute.slice(1),
                        type: 'search',
                        reload: false,
                    })
                }

            } catch (e) {
                handleException(e, false, false)
            } finally {
                this.active = false
            }
        },

        sendBsaEvent (searchTerm) {
            let event = {
                eventCategory: 'search',
                eventAction: this.type,
                eventLabel: searchTerm ?? '',
            }

            if(this.$bsa) {
                this.$bsa.event(event)

                return
            }

            const Matomo = window.Matomo?.getAsyncTracker()
            if(Matomo) {
                Matomo.trackEvent(Object.values(event))
            }
        },
    },
})
