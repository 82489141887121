<script setup>
import {useMessagesLoaded,} from "@/assets/js/src/modules/lang/_composables/useMessagesLoaded"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {useGoTo,} from "vuetify"
import colors from '@/assets/js/src/style/json/colors'
import {onMounted, onUnmounted, ref,} from "vue"
import {useRoute,} from "vue-router"
import {getActivePinia, storeToRefs,} from "pinia"
import {useAppUiStore,} from "@/assets/js/src/pinia/appUi.js"

const {messagesLoaded,} = useMessagesLoaded([ 'common', ])
const {t,} = useTranslation()
const route = useRoute()
const goTo = useGoTo()
const fab = ref(false)
const activePinia = getActivePinia()

const {
    overlay,
    sidebarOnMove,
} = storeToRefs(useAppUiStore(activePinia))

let oldScrollPos = 0

const onScroll = (event) => {
    if (!import.meta.env.SSR && route.meta.uiType !== 'TextView') {
        let scrollPos = window.pageYOffset || event.target.scrollTop || 0
        if (oldScrollPos >= scrollPos) {
            fab.value = scrollPos > 20
        } else {
            fab.value = false
        }
        oldScrollPos = scrollPos
    }
}

onMounted(()=> {
    window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
    window.removeEventListener('scroll', onScroll)
})
</script>

<template>
    <transition
        name="slide-y-reverse-transition"
    >
        <v-btn
            v-show="fab"
            aria-live="polite"
            :title="messagesLoaded ? t('async.common.button.top') : ''"
            :class="['bs-app__scroll-top-btn', 'hidden-print-only', {'notrans': sidebarOnMove, 'on-overlay': overlay}]"
            density="comfortable"
            icon
            @click="goTo(0)"
        >
            <v-icon :color="colors.black">
                bs:$vuetify.icons.mdiArrowUp
            </v-icon>
        </v-btn>
    </transition>
</template>

<style lang="scss">
.bs-app {
    .bs-app__scroll-top-btn {
        --sum-bottom-offset: max(var(--cch),calc(var(--audio-player-offset) + var(--sidebar-mobile-offset)));
        --position-margin: #{map-deep-get($bs-xl, text,textNav,padding)}px;

        position: fixed;
        right: calc(var(--position-margin) + var(--bs-sidebar-width));
        bottom: calc(#{map-deep-get($bs-xl, text,textNav,padding)}px + var(--sum-bottom-offset));
        z-index: 8;
        margin: 6px 8px;
        color: map-deep-get($bs-color, sidebar, background);
        background-color: map-deep-get($bs-color, grey);

        &.on-overlay {
            bottom: var(--position-margin) !important;
            z-index: 9997;
        }
    }
}
</style>
