import {createRouter,} from 'vue-router'
import appModulesRoutes from '@/assets/js/src/modules/autoImportRoutes'
import {textLastRoutes,} from '@/assets/js/src/modules/text/_routes'
import {notifyError,} from '@/assets/js/src/util/bugsnag'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useRouteMetaStore,} from "@/assets/js/src/pinia/routeMeta"
import {Perf,} from "@/assets/js/src/util/performance"
import {Logger,} from "@/assets/js/src/util/logger"

export function createRouterInstance ({pinia,history,head,app,}) {

    let globalThis = (1, eval)('this')

    Perf.start('createRouter')

    // Falls im state aus dem ssr eine route mit gegeben wird, dann diese initial übernehmen
    if (!import.meta.env.SSR && !window.location.href.match(/[?&]mtmPreviewMode/) && pinia.state.value.route?.fullPath && !window.location.hash) {
        window.history.replaceState(history.state, document.title, pinia.state.value.route.fullPath)
    }

    let appUiStore = useAppUiStore(pinia)
    let autocompleteStore = useAutocompleteStore(pinia)
    let routeMetaStore = useRouteMetaStore(pinia)

    let router = createRouter({
        history,
        routes: [
            ...appModulesRoutes(pinia, app),
            ...textLastRoutes(pinia),
            {
                name: 'PageNotFound',
                path: '/:catchAll(404)',
                alias: '/:catchAll(.*)',
                meta: {uiType: 'Overlay', notFound: true,},
                component: () => import(/* webpackChunkName: "PageNotFound" */ '@/assets/js/src/views/PageNotFound.vue'),
            },
        ],
        scrollBehavior (to, from, savedPosition) {
            if (savedPosition) {
                if ([ 'TextView', 'SearchView', ].includes(to.meta.uiType)) {
                    appUiStore.savedYPosition = savedPosition.y
                } else if (to.meta.landingPage) {
                    appUiStore.savedYPositionOverlay = savedPosition.y
                }

                return savedPosition
            } else if ([ 'Home', 'HomeML', ].includes(to.name) || to.meta.uiType === 'Overlay') {
                return decodeURIComponent(to.path) === decodeURIComponent(from.path) ? false : {x: 0, y: 0,}
            } else {
                return false
            }
        },
    })

    router.beforeResolve(async (to, from, next) => {
        const isNotFound = to.meta.notFound === true
        const isText = to.meta.uiType === 'TextView'
        const isSearch = to.meta.uiType === 'SearchView'

        if (!isNotFound && !isText && !isSearch) {
            routeMetaStore.type = 'notFound'
        }

        // OverlayModal immer schließen
        let modalState = await appUiStore.setOverlayModal({show: false, next,})
        if (modalState) {
            next(false)
        }

        // Die letzte Overlay Route setzen, um eine bessere Zurück-Link-Funktion zu ernmöglichen
        if (from.meta.uiType === 'Overlay' && to.meta.uiType === 'Overlay' && !import.meta.env.SSR && from.meta.registerBack && !appUiStore.menu && (from.name !== to.name)) {
            appUiStore.backOverlayRoute = from.name
        }

        // State für Ui-Elemnte setzen
        if (to.meta.uiType === 'Overlay') {
            appUiStore.setOverlay(true)
            appUiStore.claim = false
            appUiStore.menu = false
        } else if (appUiStore.overlay !== false) {
            appUiStore.setOverlay(false)
        }

        if ([ 'Home', 'HomeML', ].includes(to.name)) {
            appUiStore.home = true
        } else if (appUiStore.home !== false) {
            appUiStore.home = false
        }

        // Hilfe Tour
        if (to.meta.uiType === 'TextView') {
            appUiStore.helpTour = 'text'
        } else if (to.meta.uiType === 'SearchView') {
            appUiStore.helpTour = 'search'
            appUiStore.setBookMenuUsed(false)
        } else {
            appUiStore.helpTour = ''
            appUiStore.setBookMenuUsed(false)
        }

        if (to.name === 'PageNotFound' || to.name === 'PageNotFoundAll') {

            // Notify internal 404 links
            if (appUiStore.countRouteChange > 0) {
                let fromPath = from.fullPath
                let toPath = decodeURIComponent(to.fullPath)
                if (toPath === fromPath) {
                    fromPath = appUiStore.lastRoute?.route?.fullPath ?? 'unknown'
                }
                notifyError(new Error(`Bitte prüfe 404 Link (${toPath}) auf "${fromPath}"`))
            }
            appUiStore.pageNotFound = true
        } else if (appUiStore.pageNotFound !== false) {
            appUiStore.pageNotFound = false
        }

        if(!to.meta.sidebar) {
            appUiStore.sidebarOpenWidth = '0px'
        }

        next()
    })

    router.afterEach((to, from) => {
        if(!import.meta.env.SSR) {
            // Die letzte Route setzen, um eine bessere Zurück-Link-Funktion zu ernmöglichen
            if (from.meta.uiType !== 'Overlay' && to.meta.uiType === 'Overlay') {
                let sidebar = document.querySelector('#sidebar .aside-info-content')
                appUiStore.lastRoute = {
                    route: from,
                    scrollState: window.pageYOffset,
                    sidebarScrollState: sidebar !== null ? sidebar.scrollTop : 0,
                }
            }

            // Scroll to Position
            if (to.meta.uiType !== 'Overlay' && from.meta.uiType === 'Overlay') {
                setTimeout(function () {
                    let lastRoute = appUiStore.lastRoute
                    if (lastRoute.scrollState !== 0) {
                        window.scrollTo(0, lastRoute.scrollState)
                    }
                    let sidebar = document.querySelector('#sidebar .aside-info-content')
                    if (lastRoute.sidebarScrollState !== 0 && sidebar !== null && sidebar.scrollTo) {
                        sidebar.scrollTo(0, lastRoute.sidebarScrollState)
                    }
                    appUiStore.lastRoute =  {
                        route: lastRoute.route,
                        scrollState: 0,
                        sidebarScrollState: 0,
                    }
                    autocompleteStore.blockHideAllComponents = false
                }, 250) // overlay-slide-out transition delay
            }

            appUiStore.increaseCounter(globalThis.clone(to.fullPath))

            head.push({})
        }
    })

    router.onError((err) => {
        if (!import.meta.env.SSR && (import.meta.env.DEV || pinia.state.value.baseUrl === 'https://dev.bibleserver.com')) {
            Logger.error({err,})
        }
    })

    Perf.end('createRouter')

    return router
}
