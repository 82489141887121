import 'vuetify/styles'
import '@/assets/js/src/style/main.scss'
import colors from '@/assets/js/src/style/json/colors'
import {ref,isRef,} from 'vue'
import { createVuetify, } from 'vuetify'
import {iconSet,} from '@/assets/js/src/vuetify/iconMap'
import {DIR_RTL, useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useColorMode,} from '@vueuse/core'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {de,} from 'vuetify/locale'
import {Perf,} from "@/assets/js/src/util/performance"

export function createVuetifyInstance ({i18n,pinia,}) {
    
    let globalThis = (1, eval)('this')

    Perf.start('initVuetify')

    let mode
    (globalThis.hasLocalStorageAccess || import.meta.env.SSR) && (mode = useColorMode())
    if(!isRef(mode)) {
        mode = ref('light')
    }

    let langStore = useLangStore(pinia)
    
    if(!import.meta.env.SSR) {
        let userStore = useUserStore(pinia)

        if(typeof userStore.user.options.textFormats.darkMode !== 'undefined') {
            if(userStore.user.options.textFormats.darkMode) {
                mode.value = 'dark'
            }

            let tmp = globalThis.clone(userStore.user.options.textFormats)
            delete tmp.darkMode
            userStore.setUserOptions({
                option: 'textFormats',
                data: tmp,
            })
        }
    }

    const light = {
        dark: false,
        colors: {
            primary: colors.bsHighlight,
            secondary: colors.greyDark,
            accent: colors.bsHighlight,
            error: colors.form.error,
            background: colors.white,
            surface: '#FFFFFF',
            'primary-darken-1': '#3700B3',
            'secondary-darken-1': '#018786',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00',
        },
    }

    const dark = {
        dark: true,
        colors: {
            primary: colors.bsHighlight,
            secondary: colors.greyDark,
            accent: colors.bsHighlight,
            error: colors.form.error,
            background: colors.sidebar.background,
            surface: '#555',
            'primary-darken-1': '#3700B3',
            'secondary-darken-1': '#018786',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00',
        },
    }

    let vuetifyOptions = {
        theme: {
            disable: false,
            defaultTheme: mode.value === 'dark' ? 'dark' : 'light',
            themes: {
                light,
                dark,
            },
            options: {
                minifyTheme: function (css) {
                    return import.meta.env.PROD
                        ? css.replace(/[\r\n|\r|\n]/g, '')
                        : css
                },
                variations: false,
            },
        },
        display: {
            mobileBreakpoint: 'md',
            thresholds: {
                xs: 0,
                sm: 641,
                md: 801,
                lg: 1441,
                xl: 1441,
                xxl: 1441,
            },
        },
        rtl: langStore.localeDirection === DIR_RTL,
        icons: {
            sets: {
                bs: iconSet,
                mdi: iconSet,
            },
        },
        locale: {
            locale: langStore.locale,
            messages: {
                de,
            },
        },
        lang: { // sync i18n capabilities between vuetify and i18n
            t: (key, ...params) => {
                if(key === '$vuetify.loading') {
                    return 'loading'
                }
                if(key.indexOf('noDataText') !== -1) {
                    return ''
                }

                return i18n && i18n.t(`async.${(key.indexOf('dataFooter') !== -1 || key.indexOf('dataTable') !== -1) ? 'webmasters' : 'common'}.${key}`,params)
            },
        },
    }

    Perf.end('initVuetify')

    return createVuetify(vuetifyOptions)
}
