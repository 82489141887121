import { defineStore, } from 'pinia'
import fetch from '@/assets/js/src/util/fetch'
import { getDefaultHeaders, } from '@/assets/js/src/util/fetch/defaultHeaders'
import { checkResponse, commitMessage, handleException, } from '@/assets/js/src/util/apiTools'

const API_REPORT = '/api/report_content'
export const useContentReportStore = defineStore('contentreport', {
    state: () => ({}),
    actions: {
        async reportContent ({ content, rating, text, type, }) {
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: API_REPORT,
                    options: {
                        method: 'POST',
                        body: JSON.stringify({
                            content,
                            rating,
                            text,
                            type,
                        }),
                        headers: getDefaultHeaders({}),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                // Zeige Erfolgsmeldung
                commitMessage(apiResponse)
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, true)
            }
        },
    },
})
