<script setup>
import { useHandleRoutableLink, usePrefetchData, useTranslation, } from '@/assets/js/src/util/composables'

const emit = defineEmits([ 'closed', ])
const { t, } = useTranslation()
const { messagesLoaded, } = usePrefetchData({
    loadAsyncMessages: [ 'common', 'search', ],
})
const { handleRoutableLink, } = useHandleRoutableLink()
const handleMessageClick = (ev) => {
    emit('closed')
    handleRoutableLink(ev)
}
</script>

<template>
    <div
        v-if="messagesLoaded"
        class="tooltip-message"
    >
        <p 
            @click="handleMessageClick"
            v-html="t('async.search.info-tooltip')"
        ></p>
        <v-btn
            icon
            variant="text"
            color="black"
            class="close"
            size="small"
            :title="t('async.common.button.close')"
            @click="emit('closed')"
        >
            <v-icon>bs:$vuetify.icons.mdiClose</v-icon>
        </v-btn>
    </div>
    <span
        v-else
        class="loading-dots"
    ></span>
</template>

<style lang="scss">
#teleported .search-info-btn-tooltip {
    z-index: 9991!important;
    color: map-deep-get($bs-color, black)!important;
    background: map-deep-get($bs-color, greyLight)!important;;
    border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
        right: 6px;
        left: auto!important;
    }

    a {
        color: map-deep-get($bs-color, black)!important;
    }

    .tooltip-message {
        position: relative;
        max-width: 300px;
        padding: 16px 24px 16px 20px;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            max-width: 230px;
            padding: 12px 24px 12px 16px;
        }

        .close {
            position: absolute;
            top: -2px;
            right: -12px;
        }
    }

    .loading-dots {
        display: inline-block;
        width: 1.5rem;
        background-color: currentColor;
        pointer-events: none;
        aspect-ratio: 1/1;
        mask-size: 100%;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48c3R5bGU+LnNwaW5uZXJfcU04M3thbmltYXRpb246c3Bpbm5lcl84SFFHIDEuMDVzIGluZmluaXRlfS5zcGlubmVyX29YUHJ7YW5pbWF0aW9uLWRlbGF5Oi4xc30uc3Bpbm5lcl9aVExme2FuaW1hdGlvbi1kZWxheTouMnN9QGtleWZyYW1lcyBzcGlubmVyXzhIUUd7MCUsNTcuMTQle2FuaW1hdGlvbi10aW1pbmctZnVuY3Rpb246Y3ViaWMtYmV6aWVyKDAuMzMsLjY2LC42NiwxKTt0cmFuc2Zvcm06dHJhbnNsYXRlKDApfTI4LjU3JXthbmltYXRpb24tdGltaW5nLWZ1bmN0aW9uOmN1YmljLWJlemllcigwLjMzLDAsLjY2LC4zMyk7dHJhbnNmb3JtOnRyYW5zbGF0ZVkoLTZweCl9MTAwJXt0cmFuc2Zvcm06dHJhbnNsYXRlKDApfX08L3N0eWxlPjxjaXJjbGUgY2xhc3M9J3NwaW5uZXJfcU04MycgY3g9JzQnIGN5PScxMicgcj0nMycvPjxjaXJjbGUgY2xhc3M9J3NwaW5uZXJfcU04MyBzcGlubmVyX29YUHInIGN4PScxMicgY3k9JzEyJyByPSczJy8+PGNpcmNsZSBjbGFzcz0nc3Bpbm5lcl9xTTgzIHNwaW5uZXJfWlRMZicgY3g9JzIwJyBjeT0nMTInIHI9JzMnLz48L3N2Zz4=")
    }

    &::before {
        position: absolute;
        inset: -8px 0 auto auto;
        display: block;
        width: 20px;
        height: 20px;
        background-color: inherit;
        border: inherit;
        border-radius: 0 0 0 .25em;
        transform: translateX(-50%) scaleX(.6) rotate(135deg);
        content: ' ';
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    }
}

@media print {
    #teleported .search-info-btn-tooltip {
        display: none !important;
    }
}
</style>
