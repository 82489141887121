import {ref, onServerPrefetch, onBeforeMount, computed, useSSRContext, watch,} from 'vue'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'
import {getActivePinia, storeToRefs,} from 'pinia'
import {getInitializedApp,} from '@/assets/js/src/util/appRef'
import {CANCELED_API_CALL,} from "@/assets/js/src/util/apiTools"
import {getActiveHead,} from "@/assets/js/src/util/composables/useActiveHead"

export const LOADING = 1
export const LOADED = 2
export const UPDATING = 3
export const ERROR = 4

export function usePrefetchData ({actions,loadAsyncMessages,errorHandler,}) {

    let activePinia = getActivePinia()
    let head = getActiveHead()
    let langStore = useLangStore(activePinia)
    let routeMetaStore = useRouteMetaStore(activePinia)
    let rootState = activePinia.state.value

    const {areAsyncKeysLoaded,} = storeToRefs(langStore)

    actions = actions || []

    // Declare all data - async functions
    const asyncActions = actions.map(({action,payload,}) => {
        return async function () {
            let actionPayload = payload
            if(typeof payload === 'function') {
                actionPayload = payload()
            }

            return await action(actionPayload)
        }
    })

    // Declare the translation async function
    const loadMessages = async function () {
        if(typeof loadAsyncMessages !== 'undefined') {
            await langStore.fetchAsyncMessages({keys: loadAsyncMessages, noLoadingBar: true,})
        }
    }
    // state encapsulated and managed by the composable
    const loaded = ref(false)
    const loading = ref(false)
    const messagesLoaded = computed(()=>{
        return typeof loadAsyncMessages === 'undefined' ? true : areAsyncKeysLoaded.value(loadAsyncMessages)
    })

    onServerPrefetch(()=>{
        let asyncActionsComposed = [ ...asyncActions, ]
        if(!messagesLoaded.value) {
            asyncActionsComposed.push(loadMessages)
        }
        const ctx = useSSRContext()

        let hasCanceledApiCall = false

        return Promise.all(asyncActionsComposed.map(fn => fn()))
            .then((res)=>{
                hasCanceledApiCall = res.includes(CANCELED_API_CALL)
            })
            .catch(async (exception)=>{
                if(typeof exception.payload !== 'undefined' && [ 403, 404, ].includes(exception.payload.status )) {
                    globalThis.errorPage(exception.payload.status, ctx)
                }
            })
            .finally(()=>{
                loaded.value = isActionItemLoaded()
                loading.value = false
                if(!loaded.value && !hasCanceledApiCall) {
                    try {
                        ctx.headers.ttl = 0
                    } catch { /* empty */ }
                }
            })
    })

    let isActionItemLoaded = () => {
        return actions.every(action => {
            return Object.keys(action.item.value ?? {}).length !== (action.condition || 0)
        })
    }

    onBeforeMount(()=>{
        if(actions.length === 0) {
            loaded.value = false
        } else {
            loaded.value = isActionItemLoaded()
        }

        // Ignore if already in state
        if(!loaded.value) {
            loaded.value = false
            loading.value = true
            let asyncActionsComposed = [ ...asyncActions, ]
            if(!messagesLoaded.value) {
                asyncActionsComposed.push(loadMessages)
            }
            Promise.all(asyncActionsComposed.map(fn => fn()))
                .catch((exception) => {
                    if(typeof exception.payload !== 'undefined' && [ 404,403, ].includes(exception.payload.status )) {
                        let errRoute = {
                            name: 'PageNotFound',
                            params: {
                                error: exception.payload,
                            },
                        }

                        let {router,} = getInitializedApp(rootState.appId)
                        router.push(errRoute)
                        routeMetaStore.route = errRoute
                    }

                    if(errorHandler) {
                        errorHandler(exception)
                    }

                    loaded.value = false
                })
                .finally(() => {
                    // Um Messages zu aktualisieren
                    // proxy?.$forceUpdate()
                    loaded.value = isActionItemLoaded()
                    loading.value = false
                })
        } else if (!messagesLoaded.value) {
            loadMessages()
        }
    })

    if(actions.length === 0) {
        return {
            messagesLoaded,
        }
    }

    const asyncStatus = computed(()=>{
        if (loading.value && !loaded.value) {
            return LOADING
        }
        if (loading.value && loaded.value) {
            return UPDATING
        }
        if (!loading.value && loaded.value) {
            return LOADED
        }

        return ERROR
    })

    const allAsyncLoaded = computed(()=>{
        return asyncStatus.value === LOADED && messagesLoaded.value
    })

    watch(allAsyncLoaded, async (newBoolean, oldBoolean) => {
        if(newBoolean && newBoolean !== oldBoolean) {
            head.push({})
        }
    })

    const updateData = async function (status) {
        if(loading.value) {
            return
        }
        status = status || false
        try {
            if(status) {
                loading.value = true
            }
            let promiseAll = await Promise.all(asyncActions.map(fn => fn()))
            loaded.value = isActionItemLoaded()

            return promiseAll
        } finally {
            if(status) {
                loading.value = false
            }
        }
    }

    const verifyStatus = function () {
        loading.value = false
        loaded.value = isActionItemLoaded()
    }

    return {
        messagesLoaded,
        asyncStatus,
        allAsyncLoaded,
        updateData,
        verifyStatus,
    }
}