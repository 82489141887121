import pino from 'pino'

let globalThis = (1, eval)('this')

globalThis._bsLogger = pino ({
    level: import.meta.env.PROD ? 'error' : 'debug', // Set the log level you need (e.g., 'info', 'debug', 'warn', etc.)
    timestamp: pino.stdTimeFunctions.isoTime,
    browser: {
        asObject: true, // Logs as objects in DevTools console
        formatters: {
            level: (label) => {
                return {
                    level: label,
                }
            },
        },
    },
    transport: {
        target: 'pino-pretty', // Optional: Use pretty-printing for easier reading in the console
        options: {
            colorize: true, // Optional: Add color to the log output
        },
    },
})
