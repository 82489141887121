import { defineStore, getActivePinia, } from 'pinia'
import fetch from '@/assets/js/src/util/fetch'
import { getDefaultHeaders, } from '@/assets/js/src/util/fetch/defaultHeaders'
import { checkResponse, handleException, CANCELED_API_CALL, } from '@/assets/js/src/util/apiTools'
import { removeSpecialCharacters, } from '@/assets/js/src/util/slugify'
import { useLangStore, } from '@/assets/js/src/modules/lang/_pinia/lang'

function clearPattern (booknames) {
    for (let key in booknames.booknames) {
        booknames.booknames[key]['pattern'] = booknames.booknames[key]['pattern'].replace(/\\/g, '')
        booknames.booknames[key]['default-ff'] = booknames.booknames[key]['default-ff'] ?? removeSpecialCharacters(booknames.booknames[key]['default'])
        booknames.booknames[key]['default-ft'] = booknames.booknames[key]['default-ft'] ?? removeSpecialCharacters(booknames.booknames[key]['default'],false,true)
        booknames.booknames[key]['all-ft'] = booknames.booknames[key]['all-ft'] ?? []
        booknames.booknames[key]['all-tt'] = booknames.booknames[key]['all-tt'] ?? []

        for (let i = 0; i < booknames.booknames[key]['all'].length; i++) {
            booknames.booknames[key]['all'][i] = removeSpecialCharacters(booknames.booknames[key]['all'][i])
            booknames.booknames[key]['all-ft'][i] = removeSpecialCharacters(booknames.booknames[key]['all'][i],false,true)
            booknames.booknames[key]['all-tt'][i] = removeSpecialCharacters(booknames.booknames[key]['all'][i],true,true)
        }
    }

    return booknames
}

export const useBooknamesStore = defineStore('booknames', {
    state: () =>({
        isActive: false,
        alpha: [],
        booknames: {},
        booknamesByLocale: {},
        booknamesByLocaleLoading: false,
        booknamesLocale: '',
    }),
    getters: {
        getShortBooknameByNumber: (state) => (number) => {
            if (!Object.keys(state.booknames).length || typeof number === 'undefined') {
                return ''
            }

            return state.booknames[number].abbr
        },
        getDefaultBooknameByNumber: (state) => (number) => {
            if (!Object.keys(state.booknames).length || typeof number === 'undefined') {
                return ''
            }

            return state.booknames[number].chapter || state.booknames[number].default
        },
        booknamesLoaded: (state) => !!Object.keys(state.booknames).length,
    },
    actions: {
        async loadBooknames (locale) {
            let isReturn = false

            if (typeof locale !== 'undefined' && this.booknamesLocale !== locale) {
                try {
                    if(this.isActive) {
                        isReturn = true

                        return CANCELED_API_CALL
                    }
                    this.isActive = true
                    let useCache = true
                    let apiResponse = await fetch({
                        url: '/api/autocomplete/booknames/' + locale,
                        options: {
                            headers: getDefaultHeaders({
                                useCache,
                            }),
                        },
                        useCache,
                        tags: [
                            `locale.${locale}`,
                            'booknames',
                        ],
                    })

                    // Api-Response prüfen
                    if (checkResponse(apiResponse)) {
                        throw new Error('Fehler in Api-Response')
                    }

                    let { alpha, booknames, } = clearPattern(globalThis.clone(apiResponse.data))
                    this.alpha = alpha
                    this.booknames = booknames
                    this.booknamesLocale = globalThis.clone(locale)
                } catch (e) {
                    // Evtl. Fehler ausgeben
                    handleException(e, true)
                } finally {
                    if(!isReturn) {
                        this.isActive = false
                    }
                }
            }
        },
        async loadBooknamesByLocale (locales) {
            if(this.booknamesByLocaleLoading) {
                return
            }

            this.booknamesByLocaleLoading = true

            let activePinia = getActivePinia()
            let langStore = useLangStore(activePinia)
            let loadedLocales = Object.keys(this.booknamesByLocale)
            locales = locales.filter((locale) => {
                return loadedLocales.indexOf(locale) === -1 && langStore.locale !== locale
            })

            if (locales.length > 0) {
                locales = locales.sort()
                try {
                    let useCache = true
                    let apiResponse = await fetch({
                        url: '/api/autocomplete/booknames/locales/' + locales.join('.'),
                        options: {
                            headers: getDefaultHeaders({
                                useCache,
                            }),
                        },
                        useCache,
                        tags: [
                            'booknames',
                            ...locales.map((locale) => `locale.${locale}`),
                        ],

                    })

                    // Api-Response prüfen
                    if (checkResponse(apiResponse)) {
                        throw new Error('Fehler in Api-Response')
                    }

                    let booknamesByLocaleOld = globalThis.clone(this.booknamesByLocale)
                    let booknamesByLocale = globalThis.clone(apiResponse.data)

                    Object.keys(booknamesByLocale).forEach((key) => {
                        booknamesByLocaleOld[key] = clearPattern(booknamesByLocale[key])
                    })

                    this.booknamesByLocale = booknamesByLocaleOld
                } catch (e) {
                    // Evtl. Fehler ausgeben
                    handleException(e, true)
                } finally {
                    this.booknamesByLocaleLoading = false
                }
            } else {
                this.booknamesByLocaleLoading = false
            }
        },
    },
})
