<script setup>
import {useHandleRoutableLink,} from "@/assets/js/src/util/composables/index.js"
import {onMounted,} from "vue"

const props = defineProps({
    snackbar: {
        type: Object,
        default: ()=>({}),
    },
})
const emit = defineEmits([ 'close', ])
const {timeout, type, html, message, uniqueId,} = props.snackbar

onMounted(()=>{
    if(timeout >= 0) {
        setTimeout(()=>{
            emit('close', uniqueId)
        }, timeout)
    }
})

const {handleRoutableLink,} = useHandleRoutableLink()

const closeSnackbarOnClickedLink = function (event) {
    let {target,} = event

    if (target && target.tagName === 'A') {
        emit('close', uniqueId)
    }
}
</script>

<template>
    <transition name="fade">
        <div
            class="bs-snackbar-item"
            :class="`bs-bg-${type}`"
            @click.capture="closeSnackbarOnClickedLink"
        >
            <span
                v-if="html"
                class="snackbar-item__content"
                @click="handleRoutableLink"
                v-html="message"
            ></span>
            <span v-else>
                {{ message }}
            </span>
            <v-btn
                icon
                variant="text"
                :title="'Snackbar schließen'"
                class="bs-snackbar-item__btn--close"
                @click.prevent.stop="$emit('close', uniqueId)"
            >
                <v-icon>bs:$vuetify.icons.mdiClose</v-icon>
            </v-btn>
        </div>
    </transition>
</template>

<style lang="scss">
.bs-app .bs-snackbar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 480px;
    margin-right: var(--bs-sidebar-width);
    margin-bottom: 8px;
    padding: 8px 16px;
    color: white;
    pointer-events: auto;

    @media screen and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
        min-width: 100vw;
        margin-bottom: 4px;
    }

    &__btn--close {
        width: 36px;
        height: 36px;
        margin: 0 0 0 8px;
        color: white;
    }

    &.bs-bg-warning, &.bs-bg-info {
        background-color: map-deep-get($bs-color, snackbar, info);
    }

    &.bs-bg-error {
        background-color: map-deep-get($bs-color, snackbar, error);
    }

    &.bs-bg-success {
        background-color: map-deep-get($bs-color, snackbar, success);
    }

    &.bs-bg-help, &.bs-bg-highlight {
        background-color: map-deep-get($bs-color, bsHighlight);
    }

    a {
        color: white
    }
}
</style>