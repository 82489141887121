import {Logger,} from "@/assets/js/src/util/logger"

class PerformanceLogger {
    constructor () {
        this.performanceMarkers = {}
    }
    start (label) {
        if (!import.meta.env.SSR) {
            return
        }

        if (this.performanceMarkers[label]) {
            Logger.info(`Performance timer for '${label}' already exists.`)

            return
        }

        this.performanceMarkers[label] = {
            start: performance.now(),
        }

        Logger.info({
            label: `${label}: start timer`,
        })
    }

    end (label) {
        if (!import.meta.env.SSR) {
            return
        }

        const marker = this.performanceMarkers[label]
        if (!marker) {
            Logger.info(`No performance timer found for '${label}'.`)

            return
        }

        const end = performance.now()

        const duration = end - marker.start

        delete this.performanceMarkers[label]

        Logger.info({
            label: `${label}: end timer`,
            duration: `${duration.toFixed(2)} ms`,
        })
    }
}

export const Perf = new PerformanceLogger()
